import React from "react";
import Footer from "../components/Footer";

const Tos = () => {
  return (
    <div className="w-full my-0 mx-auto md:px-0 px-3">
      <div className="w-full pt-24 border-t border-[rgba(255,255,255,.12)]">
        <div className="container max-w-[1280px] mx-auto my-24 lg:flex">
          <div className="lg:w-1/3 md:text-right text-center md:pr-8">
            <h1 className="uppercase text-white text-[36px] leading-[44px] font-[900]">
              DETAILS
            </h1>
          </div>
          <div className="lg:w-2/3 md:pl-10 pl-3 lg:pr-40 lg:text-start text-center">
            <p className="mb-8">
              The terms and conditions of Fragnet Networks govern your rights
              and obligations, and those of Fragnet Networks in relation to the
              services offered by Fragnet Networks through our web site and our
              game, voice and web servers. Please take some time to read through
              these terms and conditions. By subscribing to Fragnet Networks
              game servers and/or using the Fragnet Networks web site you accept
              and agree to be bound by these terms and conditions.
            </p>
            <h5 className="text-white font-bold">Definitions:</h5>
            <p className="mb-8">
              For ease of reference, certain words used throughout the terms and
              conditions are given a specific meaning. These meanings are set
              out below:
            </p>
            <p className="mb-8">
              "Fragnet Networks" means the Fragnet.net Web Site and Game Server
              Hosting Service, affiliated services hosted and sold through
              partner sites owned and operated by Fragnet Networks
            </p>
            <p className="mb-8">
              "Server Application" means software which provides access from one
              User to another User. Users use client software running on a PC to
              connect to the server software.
            </p>
            <p className="mb-8">
              "Debranding" means a configurable option that can be purchased,
              which waives the requirement to have "Fragnet.net " in the
              beginning of the server name. By removing the branding, you risk
              getting your game server automatically suspended by our monitoring
              system.
            </p>
            <p className="mb-8">
              "Game Server" means a Fragnet Networks system of software and
              hardware that allows a User to connect to other Users within a
              single Server Application via the Internet. Besides just games,
              Game Server can refer to Voice Servers and any other related
              services.
            </p>
            <p className="mb-8">
              "Fragnet Networks" means the Fragnet.net Web Site and Game Server
              Hosting Service, affiliated services hosted and sold through
              partner sites owned and operated by Fragnet Networks
            </p>
            <p className="mb-8">
              "Game Server Hosting" means the rental of the Fragnet Networks
              Game Servers to Users
            </p>
            <p className="mb-8">
              "Services" means the services offered by Fragnet Networks via the
              Fragnet.net web site, game servers or any other server
              applications including but not limited to voice servers, FTP
              servers or web hosting servers.
            </p>
            <p className="mb-8">
              "Fragnet Networks" means the Fragnet.net Web Site and Game Server
              Hosting Service, affiliated services hosted and sold through
              partner sites owned and operated by Fragnet Networks
            </p>
            <p className="mb-8">
              "Entry Password" means a password that lets you enter or join an
              existing Game Server to play
            </p>
            <p className="mb-8">
              "Customer" means any User that is a Game Server Hosting subscriber
              who has paid in full for Game Server Hosting services, and is in
              good standing
            </p>
            <p className="mb-8">
              "Fragnet Networks" means the Fragnet.net Web Site and Game Server
              Hosting Service, affiliated services hosted and sold through
              partner sites owned and operated by Fragnet Networks
            </p>
            <p className="mb-8">
              "Admin User Name and Password" means one or more user names and
              passwords that can be used by the Customer for administering their
              Fragnet Networks Game Server
            </p>
            <p className="mb-8">
              "Maxplayers" means the number of players that may join the
              Customer's Game Server at a single time. This number is typically
              determined by the Customer at the time of purchase.
            </p>
            <p className="mb-8">
              "Protected" Game Server means a Game Server that must always
              remain locked so that players cannot join unless they enter the
              correct Entry Password when prompted
            </p>
            <p className="mb-8">
              "Public" within this agreement is defined to mean either openly
              available or such that no Entry Password is in place on the game
              server
            </p>
            <p className="mb-8">
              "Public Game Server" means a Game Server that is unlocked such
              that any player can join without being prompted for a Entry
              Password
            </p>
            <p className="mb-8">
              "User" means any party using the Web Site or other Fragnet
              Networks services
            </p>
            <p className="mb-8">
              "Trusted User" means any person that the Customer has given admin
              rights to and allows this person to contact Fragnet Networks on
              behalf of the Customer for changes, passwords, etc from Customer's
              Game Server
            </p>
            <p className="mb-8">
              "Web Site" means Fragnet Networks's web site hosted at
              www.Fragnet.net or such other web site as Fragnet Networks may use
              from time to time
            </p>
            <p className="mb-8">
              "Clan Site" means Customer's web site as hosted through Fragnet
              Networks or Fragnet Networks's web hosting partner(s)
            </p>
            <p className="mb-8">
              "Administrator" means a member of Fragnet Networks's
              Administration staff
            </p>
            <p className="mb-8">
              "User's Clanmates" means the online friends or team mates of the
              User who also participates in the User's Fragnet Networks Services
            </p>
            <h5 className="text-white font-bold">Registration:</h5>
            <p className="mb-8">
              When signing up for game server hosting Services, each User agrees
              to register using his/her correct name, address, and other
              requested details. In the event that any such information changes
              the User must notify Fragnet Networks immediately of such change.
            </p>
            <h5 className="text-white font-bold">Acknowledgment:</h5>
            <p className="mb-8">
              You specifically acknowledge that Fragnet Networks and its
              affiliates are not a part of or associated with the Valve
              Corporation.
            </p>
            <h5 className="text-white font-bold">Legal Game Ownership:</h5>
            <p className="mb-8">
              Each User is required to own a licensed copy of any and all
              software used in the server hosting that we run on his or her
              behalf including, but not limited to, game clients, game servers,
              voice clients, voice servers, FTP clients, etc.
            </p>
            <h5 className="text-white font-bold">
              Reselling of RANKED Services:
            </h5>
            <p className="mb-8">
              Reselling of RANKED Services, such as Battlefield 3, Battlefield 4
              and DayZ SA is strictly prohibited. Any such activity may lead to
              immediate cancellation with no rights to refund
            </p>
            <h5 className="text-white font-bold">User Names and Passwords: </h5>
            <p className="mb-8">
              Each Customer will receive an Admin User Name and Password to use
              and administer their Game Server, and an Entry Password, when
              applicable, needed to join the game server. The Admin User Name
              and Password is not to be made Public knowledge. The Customer is
              responsible for all access to the rented Game Server and use of
              the Services utilizing the user names and passwords. The Customer
              shall not disclose any user name, password or other security
              information supplied by Fragnet Networks to the Public. If it is
              suspected that an Entry Password has been made Public, then
              Fragnet Networks reserves the right to change the Entry password
              and e-mail the new one to the Customer. Repeated infractions may
              result in account suspension. Customers may change their password
              as needed by contacting Fragnet Networks with the new desired
              passwords, or modifying the password themselves.
            </p>
            <p className="mb-8">
              If a Customer has forgotten his/her password, then that Customer
              or pre-determined Trusted User must contact us via email in order
              for us to remind or reset the login information. The Customer may
              provide us with a list of Trusted Users who may also make a
              request for and receive a reminder or reset of login information.
              This list of additional Users must contain an e-mail, game handle,
              and full name, of each User, prior to the User needing this
              special access.
            </p>
            <h5 className="text-white font-bold">Server Port Numbers</h5>
            <p className="mb-8">
              Each Customer's Server Application is assigned to specific port
              numbers in the Server Application config files. These port numbers
              are never to be changed by Customer or Customer's admins and in
              doing so may disable your server and other servers on the machine.
              Because of this possible impact, if Customer changes any port
              numbers on Customer's Application Server then Customer may be
              faced with a mandatory $25 setup fee to reinstall or fix the
              server without warning.
            </p>
            <h5 className="text-white font-bold">
              Changes to the Web Site and Key Service Issues:
            </h5>
            <p className="mb-8">
              The User acknowledges and accepts that Fragnet Networks may from
              time to time alter any aspect of the Web Site, or any of the
              services or products provided through the Web Site, as it thinks
              fit and without notice to the User. The User accepts that it will
              have no claim for breach of contract or otherwise in respect of
              any such alteration. Fragnet Networks reserves the right to e-mail
              Users at the e-mail address provided to Fragnet Networks by Users
              regarding any key service issues and it will be presumed that such
              e-mails will have been read. Fragnet Networks may run automated
              searches for abuse of its Services and Customers are deemed to
              have accepted this limited intrusion into their rented Services
              and Fragnet Networks accounts.
            </p>
            <h5 className="text-white font-bold">Updates:</h5>
            <p className="mb-8">
              These TOS may be updated or changed from time to time. You can
              review the most current version of these TOS at any time. If
              Fragnet Networks makes a change to these TOS and that change has a
              material impact on services, you will be provided notice of the
              change. Your continued use of services following such notice
              constitutes your acceptance of those changes.
            </p>
            <h5 className="text-white font-bold">User Conduct:</h5>
            <p className="mb-8">
              The User warrants that in its use of the Web Site and Services it
              shall:
            </p>
            <p className="mb-8">
              1) Not, beyond normal use, act in any manner which negatively
              impacts on the equipment or network of Fragnet Networks or is
              likely to lower Fragnet Networks's quality or brand image in the
              larger Internet community.{" "}
            </p>
            <p className="mb-8">
              2) Use the Web Site only for lawful purposes, and in a manner
              which does not infringe the rights of, or restrict or inhibit the
              use of the Web Site by any third party
            </p>
            <p className="mb-8">
              3) Not engage in any conduct which is unlawful, or which may
              harass or cause distress or inconvenience to any person.
            </p>
            <p className="mb-8">
              4) Not upload, post, transmit or distribute any material or
              information in which the User does not own or has license to the
              intellectual property rights, or any material which is in any way
              unlawful or which is potentially harmful, threatening, abusive,
              libelous, pornographic or otherwise obscene, or racially or
              ethically or otherwise objectionable; and
            </p>
            <p className="mb-8">
              5) Not upload, post, transmit or distribute any material or
              information which contains a computer virus, or other code, files
              or programs intended or having for effect to disrupt, crash, or
              otherwise adversely affect the operation of the Clan Site, Game
              Servers, Services, or of other Users' computer systems.
            </p>
            <p className="mb-8">
              6) Be aware that in using Fragnet Networks, Users may travel over
              other networks or be involved in the use of other services. Users
              understand that they will be bound by the Terms and Conditions
              imposed by the operators of these networks and services.
            </p>
            <h5 className="text-white font-bold">Games Conduct:</h5>
            <p className="mb-8">
              When playing on Fragnet Networks's own maintained demo game
              servers, Users must act in a sportsmanlike manner at all times.
              Malicious behavior will not be tolerated.
            </p>
            <p className="mb-8">
              For Customer rented Game Servers, players are advised to follow
              the rules set forth by the Customer and the Customer's designated
              game server administrator(s).
            </p>
            <h5 className="text-white font-bold">Game Server FTP Use:</h5>
            <p className="mb-8">
              The FTP access and file space provided with Fragnet Networks game
              servers is to only be used for game files directly related to the
              game server. The FTP space is not to be used as a file store nor a
              file share with other people. Customer may use their web site for
              that purpose.
            </p>
            <h5 className="text-white font-bold">Anti-Cheat Policy:</h5>
            <p className="mb-8">
              When playing on Fragnet Networks's public demo Game Servers,
              players who are suspected of cheating or hacking may be banned
              from all Fragnet Networks servers (both customer servers and
              Fragnet Networks's own maintained game servers), and may have
              their handles, unique player IDs, IP addresses, and any other
              collected information made public and/or sent to community
              blacklists.
            </p>
            <p className="mb-8">
              Fragnet Networks's customers individual communities each have
              their own set of rules usually posted on their sites or forums,
              Users are expected to learn them and obide by them.
            </p>
            <h5 className="text-white font-bold">Account Security:</h5>
            <p className="mb-8">
              You will receive a password and Member ID upon completing the
              Service registration process. You agree to keep confidential all
              passwords and computer names and are solely responsible for any
              liability or damages resulting from your failure to maintain that
              confidentiality. You are also solely and fully responsible and
              liable for all activities that occur under your password or Member
              ID. You agree to do all of the following: (a) immediately notify
              Fragnet Networks if you suspect any breach of security such as
              loss, ID, or any credit or charge card number provided to Fragnet
              Networks by contacting Fragnet Networks immediately.
            </p>
            <h5 className="text-white font-bold">User’s Assumption of Risk:</h5>
            <p className="mb-8">
              There is a risk that other users may attempt to access your
              computer through the Internet or connected networks. You
              acknowledge this risk as inherent to the shared nature of the
              services and you agree to take full responsibility for taking
              adequate security precautions and safeguarding your data from
              loss.
            </p>
            <h5 className="text-white font-bold">International Use:</h5>
            <p className="mb-8">
              Recognizing the global nature of the Internet, you agree to comply
              with all local rules regarding online conduct and acceptable
              Content. Specifically, you agree to comply with all applicable
              laws regarding the transmission of technical data exported from
              the United States or the country in which you reside.
            </p>
            <h5 className="text-white font-bold">Restricted Use:</h5>
            <p className="mb-8">
              You agree not to permit anyone else to use your membership data,
              such as password, and that each account may only be used by one
              member of your household or business. You agree that services are
              not to be used to host peer-to-peer applications that you are not
              actively using. You agree that the services are not to be used to
              trunk or facilitate public internet access (“Hotspots”) or any
              other Public Use of the Service.
            </p>
            <h5 className="text-white font-bold">Game Server Limits on Use:</h5>
            <p className="mb-8">
              For all Fragnet Networks Game Servers, Maxplayers can not be
              increased. Spectators, bots, summoned creatures are counted as
              players due to the increase in server resources they require,
              therefore, the total number of players + bots + summoned creatures
              should be kept less than or equal to your game server's max player
              limit.
            </p>
            <p className="mb-8">
              Each Game Server has a 1000 MB disk space quota. If Customer
              exceeds disk space, Customer will get a warning via email to the
              account owner and if the additional disk space needed to cover
              Customer's file usage is not purchased or if the disk usage isn't
              brought down to under the Customer's current quota within 48 hours
              then some of Customer's files may be deleted or the account will
              be charged for the extra disk space by Fragnet Networks
            </p>
            <p className="mb-8">
              For Protected Game Servers the Entry Password can not be removed.
            </p>
            <h5 className="text-white font-bold">
              Protected Game Server Overage:
            </h5>
            <p className="mb-8">
              The Entry Password cannot be removed, however, it can be changed
              as often as you would like. If the Entry Password to the
              Customer's Protected Game Server is removed 3 times or more during
              a 30 day period, then the Game Server will be considered to have
              been upgraded to become a Public Game Server as of the first
              occurance of the Entry Password removal and the Customer will
              immediately be required to pay for the difference in cost for this
              Public Game Server. The Game Server may be shut down until payment
              has been made. If there is a credit on the Customer's account,
              then the Game Server will remain up and the difference in cost
              will be deducted from the Customer's credit.
            </p>
            <h5 className="text-white font-bold">
              Backups and important data:
            </h5>
            <p className="mb-8">
              For all Fragnet Networks services it is up to the customer to
              frequently backup important files and configurations. For the free
              MySQL database services Fragnet Networks do provide backups, with
              a retention period of 7 days.
            </p>
            <h5 className="text-white font-bold">
              Operational Limits; Force Majeure:
            </h5>
            <p className="mb-8">
              Provisioning of services is subject to the availability and the
              operational limitations of the requisite equipment and associated
              facilities. You understand and agree that temporary interruptions
              of services may occur as normal events in the provision of
              services and that Fragnet Networks is not liable for such
              interruptions. You further understand and agree that Fragnet
              Networks has no control over third party networks you may access
              in the course of your use of the services, and therefore, delays
              and disruptions of other network transmissions are beyond the
              control of Fragnet Networks In addition, Fragnet Networks will not
              be liable for any failure of performance, if such failure is due
              to any cause beyond Fragnet Networks’s reasonable control,
              including acts of God, fire, explosion, vandalism, nuclear
              disaster, sun spots, solar flares, terrorism, cable cut, storm or
              other similar occurrence, any law, order, regulation, direction,
              action, or request by any government, civil, or military
              authority, national emergencies, insurrections, riots, wars, labor
              difficulties, supplier failures, shortages, breaches, or delays,
              or suspension of existing Service in compliance with the state
              and/or federal law, rules and regulations, or delays caused by you
              or your equipment.
            </p>
            <h5 className="text-white font-bold">Reversed Payments:</h5>
            <p className="mb-8">
              The customer agrees to contact Fragnet Networks by email at
              support@Fragnet.net
            </p>
            <h5 className="text-white font-bold">Late Payment:</h5>
            <p className="mb-8">
              If the Customer fails to pay any fees owed after 60 days of the
              invoice date, then late charges of one and one-half (1.5%) per
              month or the maximum allowable under applicable law shall also
              become payable by the Customer to Fragnet Networks. In the event
              of collection enforcement, Customer shall be liable for any costs
              associated with such collection, including without limitation,
              legal costs, attorneys' fees, court costs and collection agency
              fees.
            </p>
            <p className="mb-8">
              We may report information about your account to credit bureaus.
              Late payments, missed payments or other defaults on your account
              may be reflected in your credit report.
            </p>
            <h5 className="text-white font-bold">
              Effect of Failure to Comply:
            </h5>
            <p className="mb-8">
              Failure to comply with any Terms and Conditions may find the User
              being subject to, but not limited to:
            </p>
            <p className="mb-8">1) A formal warning.</p>
            <p className="mb-8">
              2) Suspension or removal of one or all of a Customer's Services.
            </p>
            <p className="mb-8">
              3) Additional charges to a customer's account and/or early
              termination of customer's server.
            </p>
            <p className="mb-8">
              User, User's Clanmates, User's Admins, or any person who benefited
              either directly or indirectly from the use of Customer's Services
              with Fragnet Networks, whether with knowledge or without of any
              wrong doing by User, will be removed of all Internet access to the
              Customer's Services being used, or all services on Fragnet
              Networks's entire nationwide network especially in the cases of
              payment fraud or overdue debt, though not limited to only these
              cases.
            </p>
            <p className="mb-8">
              1) Termination of a Customer's Services of Fragnet Networks
            </p>
            <p className="mb-8">
              2) A kick or ban of User, User's Clanmates, and User's Admins from
              Fragnet Networks's own maintained hosted game servers (the length
              of the ban to be determined by the Administrator)
            </p>
            <p className="mb-8">
              3) A Fragnet Networks network wide ban of User, User's Clanmates,
              and User's Admins from all Fragnet Networks hosted servers,
              handles, game ids (SteamIDs, etc), personal IP addresses and any
              other collected data of User, User's Clanmates, and User's Admins
              may be made public and/or added to community blacklist(s).
            </p>
            <p className="mb-8">
              Any decision made by Fragnet Networks with regards to a User's
              failure to comply will be final and all cases will be considered
              individually upon their merits.
            </p>
            <h5 className="text-white font-bold">Cancellation Policy:</h5>
            <p className="mb-8">
              You may cancel at any time. All customers are on a subscription
              basis and the subscription will renew until the customer cancels.
              Service will continue until payments already received have been
              exhausted. There will be no refunds (pro-rata or in full) after
              the account creation e-mail has been sent. User's services will be
              terminated after the account has been cancelled and any
              pre-payments have been exhausted.
            </p>
            <p className="mb-8">
              To cancel your account, you must login to your customer area, go
              to your services and submit a cancellation request. For security
              reasons, this is the only accepted method of cancellation. If you
              have a paypal subscription, you must cancel your paypal
              subscription directly though paypal. You can do this by logging
              into your paypal account, clicking your Fragnet.net subscription
              and then clicking cancel subscription. Failure to cancel your
              Paypal subscription may result in Paypal automatically taking
              payment from your account.
            </p>
            <h5 className="text-white font-bold">Legal Authority:</h5>
            <p className="mb-8">
              You must have partental concent or be an adult of at least 18
              years of age to register as an Account holder or have legal
              capacity to contract; and by accepting these TOS as an Account
              holder, you confirm that you are an adult of at least 18 years of
              age or have legal capacity to contract in your jurisdiction.
            </p>
            <h5 className="text-white font-bold">
              Computer and Telecommunications Faults:
            </h5>
            <p className="mb-8">
              The User acknowledges and accepts that computer and
              telecommunications systems are not fault free and may from time to
              time require periods of downtime (being periods during which the
              Web Site is not available to the User) for the purposes of repair,
              maintenance and upgrading. Accordingly Fragnet Networks does not
              guarantee uninterrupted availability of the Web Site, but it shall
              make reasonable effort to minimize any periods during which the
              Web Site is not available. The User accepts that he or she shall
              have no claim for breach of contract or otherwise in respect of
              any such period of unavailability.
            </p>
            <h5 className="text-white font-bold">
              Copyright and other Intellectual Property Rights:
            </h5>
            <p className="mb-8">
              The User acknowledges that all copyright, trademarks (registered
              or unregistered), know-how, design rights (registered or
              unregistered) and all other intellectual property rights in the
              Material, the Web Site and the Services shall belong to Fragnet
              Networks and the User undertakes not to infringe any of the said
              rights. Other than to the extent required to access and view the
              Web Site, or for approved affiliates of Fragnet Networks, no
              copying, distribution, modification of the Web Site or any
              Material is permitted. Resale or supply of any Material is
              expressly prohibited.
            </p>
            <p className="mb-8">
              The User acknowledges that certain Material is supplied under
              license and that the intellectual property rights in such Material
              is owned by the licensor. Neither Fragnet Networks nor the
              licensor will be responsible for any errors or corruption in the
              Material provided.
            </p>
            <h5 className="text-white font-bold">Software Piracy</h5>
            <p className="mb-8">
              The user acknowledges that they own a legitimate purchased copy of
              the game or games for which they are acquiring servers. If they
              are renting a server for a mod, the user acknowledges that they
              own a legitimate copy of the game for which the mod was created.
              Furthermore, the user agrees that they will not alter their server
              in any way so as to disable, nullify or circumvent any copy
              protection safeguards that may be put in place by the software
              vendor.
            </p>
            <h5 className="text-white font-bold">
              Rules Relating To Communication:
            </h5>
            <p className="mb-8">
              Users shall not use any unlawful, harassing, defamatory, abusive,
              threatening, harmful, obscene, profane, sexually orientated,
              racially offensive, or otherwise objectionable material. This
              includes (but is not limited to) text, graphics, video, programs
              or audio material or programs of any kind. Fragnet Networks
              accepts no responsibility for the content of any postings by Users
              and retains the right to remove any posting which, in its opinion,
              is in breach of these Terms and Conditions.
            </p>
            <p className="mb-8">
              Users shall not advertise any products, services or refer to
              specific web site addresses without obtaining consent of Fragnet
              Networks. Users will only post material which they own in
              copyright or have license to post and must not infringe the rights
              of any third party including (but not limited to) copyright, trade
              mark, trade secret, privacy or publicity rights. As a User, you
              agree to indemnify Fragnet Networks against any damages, loss,
              costs or expenses arising from any posted material, including but
              not limited to any claim by any third party that such use
              infringes its intellectual property rights.
            </p>
            <h5 className="text-white font-bold">Limitation of Liability:</h5>
            <p className="mb-8">
              IN NO EVENT WILL Fragnet Networks BE LIABLE TO CUSTOMER FOR ANY
              CONSEQUENTIAL, DIRECT OR INDIRECT, SPECIAL, INCIDENTAL DAMAGES OR
              ANY LOSS OF PROFIT, REVENUE, DATA OR GOODWILL WHETHER INCURRED OR
              SUFFERED AS A RESULT OF UNAVAILABILITY OF THE SERVICES,
              PERFORMANCE, NONPERFORMANCE, NEGLIGENCE, TERMINATION, BREACH OR
              ACTION OR INACTION UNDER THIS AGREEMENT EVEN IF CLIENT ADVISES
              Fragnet Networks OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE. THE
              FOREGOING LIMITATION ON LIABILITY, HOWEVER, SHALL NOT RELIEVE
              Fragnet Networks FROM LIABILITY FOR DIRECT DAMAGES ARISING SOLELY
              OUT OF Fragnet Networks'S GROSS NEGLIGENCE OR WANTON OR WILLFUL
              MISCONDUCT IN PERFORMANCE OF THE HOSTING SERVICES.
            </p>
            <h5 className="text-white font-bold">Indemnification:</h5>
            <p className="mb-8">
              Customer agrees to indemnify, defend, and hold harmless Fragnet
              Networks, its directors, officers, employees and agents, and
              defend any action brought against same with respect to any claim,
              demand, cause of action, debt or liability, including without
              limitation, reasonable attorneys' fees, to the extent that such
              action is based upon a claim that: (i) if true, would constitute a
              breach of any of Customer's representations, warranties, or
              agreements hereunder; (ii) arises out of the gross negligence or
              willful misconduct of Customer's; or (iii) any of the Customer's
              Content to be provided by Customer hereunder or other material on
              the Web Sites or Game Servers infringes or violates any rights of
              third parties, including without limitation, rights of publicity,
              rights of privacy, patents, copyrights, trademarks, trade secrets,
              and/or licenses.
            </p>
            <h5 className="text-white font-bold">DISCLAIMER OF WARRANTIES</h5>
            <h5 className="text-white font-bold">
              YOU EXPRESSLY UNDERSTAND AND AGREE THAT:
            </h5>
            <p className="mb-8">
              1. YOUR USE OF THE SERVICE AND/OR SOFTWARE IS AT YOUR SOLE RISK.
              THE SERVICE AND/OR SOFTWARE ARE PROVIDED ON AN “AS IS’ AND “AS
              AVAILABLE” BASIS. Fragnet NETWORKS AND ITS SUBSIDIARIES,
              AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS
              EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
              IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
              NON-INFRINGEMENT.
            </p>
            <p className="mb-8">
              2. Fragnet NETWORKS AND ITS AFFILIATE, OFFICERS, EMPLOYEES,
              AGENTS, PARTNERS AND LICENSORS MAKE NO WARRANTY THAT (I) THE
              SERVICE AND/OR SOFTWARE WILL MEET YOUR REQUIREMENTS, (ii) THE
              SERVICE AND/OR SOFTWARE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
              ERROR-FREE (FOR EXAMPLE BUT WITHOUT LIMITATION, Fragnet NETWORKS
              DOES NOT WARRANT THAT YOU WILL ALWAYS RECEIVE EMAILS ADDRESSED TO
              YOU), (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
              SERVICE AND/OR SOFTWARE WILL BE ACCURATE OR RELIABLE, (iv) THE
              QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL
              PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE AND/OR SOFTWARE
              WILL MEET YOUR EXPECTATIONS, AND (v) ANY ERRORS IN THE SERVICE
              AND/OR SOFTWARE WILL BE CORRECTED.
            </p>
            <p className="mb-8">
              3. A SMALL PERCENTAGE OF USERS MAY EXPERIENCE EPILEPTIC SEIZURES
              WHEN EXPOSED TO CERTAIN LIGHT PATTERNS OR BACKGROUNDS ON A
              COMPUTER SCREEN OR WHILE USING THE SERVICE. CERTAIN CONDITIONS MAY
              INDUCE PREVIOUSLY UNDETECTED EPILEPTIC SYMPTOMS EVEN IN USERS WHO
              HAVE NO HISTORY OF PRIOR SEIZURES OR EPILEPSY. IF YOU, OR ANYONE
              IN YOUR FAMILY, HAVE AN EPILEPTIC CONDITION, CONSULT YOUR
              PHYSICIAN PRIOR TO USING THE SERVICE. IMMEDIATELY DISCONTINUE USE
              OF THE SERVICE AND CONSULT YOUR PHYSICIAN IF YOU EXPERIENCE ANY OF
              THE FOLLOWING SYMPTOMS WHILE USING THE SERVICE ? DIZZINESS,
              ALTERED VISION, EYE OR MUSCLE TWITCHES, LOSS OF AWARENESS,
              DISORIENTATION, ANY INVOLUNTARY MOVEMENT, OR CONVULSIONS.
            </p>
            <h5 className="text-white font-bold">Arbitration:</h5>
            <p className="mb-8">
              Any dispute, controversy, or claim arising out of, or related to
              the interpretation of this contractual agreement will be resolved
              by a professional arbitrator appointed by Fragnet Networks Unless
              otherwise agreed by the parties, the dispute will be conducted as
              an e-mail/desk hearing in accordance with the rules of commercial
              arbitration of the arbitrator, and the decision of the arbitrator
              shall be binding and shall be deemed final. Judgment upon the
              award of the arbitrator may be entered in any court having
              jurisdiction thereof. The arbitration award may include reasonable
              fees and expenses of arbitration. Unless otherwise agreed, all
              disputes will be decided pursuant to, and in accordance with, the
              laws of Sweden. The exclusive forum for any legal proceedings
              which arise out of the terms of this Agreement shall be the court
              sitting in the Karlskrona, Sweden, and the parties hereby submit
              themselves and their property to the personal jurisdiction and
              venue thereof.
            </p>
            <h5 className="text-white font-bold">
              Resource Demanding Mods and Plugins:
            </h5>
            <p className="mb-8">
              For all Fragnet Networks Game Servers, any configurations which
              use mods or plugins which require an excessive amount of resources
              such as CPU, memory, or bandwidth may be removed by Fragnet
              Networks staff without warning.
            </p>
            <p className="mb-8">
              Bots are considered very demanding on CPU resources, and therefore
              the limit on bots is set at 10% of the max player limit. A 20 slot
              server would be restricted to 2 bots. A 40 slot server restricted
              to 4 bots, etc.
            </p>
            <h5 className="text-white font-bold">Valid Coupon Code Uses:</h5>
            <p className="mb-8">
              Coupon codes can only be used for what they are advertised for. If
              a customer uses a coupon code for a service or product not
              advertised as a valid use, then the amount is subject to
              adjustment or change without notice.
            </p>
            <h5 className="text-white font-bold">Acceptance of Terms:</h5>
            <p className="mb-8">
              In using any Fragnet Networks Service, including but not limited
              to being anonymous or as a Customer, the User agrees to be bound
              by these Terms and Conditions. Failure to accept these Terms and
              Conditions means User does not have permission to access the
              contents of the web site and associated services and he/she should
              cease the use of these immediately.
            </p>
            <h5 className="text-white font-bold">
              VIOLATION OF THE TERMS AND CONDITIONS CONTAINED IN THIS DOCUMENT
              MAY RESULT IN IMMEDIATE TERMINATION OF CUSTOMER'S ACCOUNT WITH NO
              REFUND!
            </h5>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Tos;
