import {Currency} from "./reducers/currencySlice";

export const cpuConfigs = [
    {label: 1, configId: 502694},
    {label: 2, configId: 502697},
    {label: 3, configId: 502700},
    {label: 4, configId: 502703},
]

export const diskConfigs = [
    {label: 10, configId: 502673},
    {label: 20, configId: 502676},
    {label: 30, configId: 502679},
    {label: 40, configId: 502682},
    {label: 50, configId: 502685},
    {label: 60, configId: 502688},
    {label: 70, configId: 502787},
    {label: 80, configId: 502790},
    {label: 90, configId: 502793},
    {label: 100, configId: 502796},
    {label: 110, configId: 502799},
    {label: 120, configId: 502802},
];

export const ramConfigs = [
    {label: 1, configId: 502721},
    {label: 2, configId: 502736},
    {label: 3, configId: 502739},
    {label: 4, configId: 502742},
    {label: 5, configId: 502745},
    {label: 6, configId: 502748},
    {label: 7, configId: 502751},
    {label: 8, configId: 502754},
    {label: 9, configId: 502760},
    {label: 10, configId: 502763},
    {label: 11, configId: 502766},
    {label: 12, configId: 502769},
    {label: 13, configId: 502772},
    {label: 14, configId: 502775},
    {label: 15, configId: 502778},
    {label: 16, configId: 502781},
];

export const baseConfigs = {
    cpu: 503192,
    ram: 503219,
    disk: 503156,
    slots: 503036,
}

export const billingPeriods = {
    'monthly': 'monthly',
    'quarterly': 'quarterly',
    'semiannually': 'semiAnnually',
    'annually': 'yearly',
}

export const currencies: Currency[] = [
    { id: 1, code: "eur", name: "€" },
    { id: 2, code: "gbp", name: "£" },
    { id: 3, code: "sek", name: "kr" },
    { id: 4, code: "usd", name: "$" },
]
