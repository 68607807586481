import React from "react";
import { useParams } from "react-router-dom";

import GameTemplateTwo from "./GameTemplateTwo";
import {getGame} from "../utils";
import {useQuery} from "@tanstack/react-query";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const GamePage: React.FC = () => {
  const { slug } = useParams();

    const {
        isLoading: gameLoading,
        data: game,
        error: gameError,
        refetch,
    } = useQuery({
        queryKey: ['game', slug],
        queryFn: () => slug ? getGame(slug) : Promise.reject(new Error("Slug is undefined")),
        enabled: !!slug
    });

    if (slug === undefined) {
        return (
            <div className="error-container text-center py-[200px]">
                <p className="text-white text-[24px] font-[600] mb-4">Game slug is not specified.</p>
            </div>
        );
    }

  if (gameLoading) {
    return (
        <div className="flex flex-col items-center justify-center py-[200px]">
          <FontAwesomeIcon icon={faSpinner} className="w-[100px] h-[100px] mb-10" spin />
          <h1 className="text-[24px] font-[600] text-white">
            Loading...
          </h1>
        </div>
    );
  }

  if (gameError) {
    return (
        <div className="error-container text-center py-[200px]">
          <p className="text-white text-[24px] font-[600] mb-4">Failed to load the game. Please try again later.</p>
          <button className="retry-button" onClick={() => refetch()}>Retry</button>
        </div>
    );
  }

  return (
      <>
        {gameLoading && (
            <div className="flex flex-col items-center justify-center py-[200px]">
              <FontAwesomeIcon icon={faSpinner} className="w-[100px] h-[100px] mb-10" spin />
              <h1 className="text-[24px] font-[600] text-white">
                Loading...
              </h1>
            </div>
        )}
        {game && (
            <GameTemplateTwo game={game} />
        )}
          {/* TODO: Handle non fragify games*/}
      </>
  )
};

export default GamePage;
