import React from "react";
import Faq from "react-faq-component";
import { Link } from "react-router-dom";
import styled from "styled-components";

const FaqWrapper = styled.div`
  .faq-row-wrapper {
    background: 0 0;
    border: 1px solid rgba(255, 255, 255, 0.12);
    margin-bottom: 20px !important;
    border-radius: 6px;
    text-align: left;
    .faq-body {
      .faq-row {
        border: none;
        .row-title {
          padding: 0.5rem 3rem 0rem 1.5rem;
          color: #fff;
          font-size: 16px;
          line-height: 28px;
          font-weight: 700;
          font-family: Roboto, sans-serif;
          text-transform: uppercase;
          .icon-wrapper {
            margin-top: 3px;
            svg {
              fill: white;
            }
          }
        }

        .row-content {
          .row-content-text {
            padding: 10px 48px 24px 24px;
            color: #a7aebd;
          }
        }
      }
    }
  }
`;
const FAQ = () => {
  const data = [
    {
      rows: [
        {
          title: (
            <h1 className="inline-flex items-center">
              <img
                className="py-2 pr-2"
                src="/question-mark.png"
                alt="question"
              />
              HOW LONG DOES IT TAKE TO SETUP MY SERVER?
            </h1>
          ),
          content: (
            <p>
              All Game Servers are setup instantly after payments are processed.
              The process starts right away and your service is online within
              the matter of a few minutes.
            </p>
          ),
        },
      ],
    },
    {
      rows: [
        {
          title: (
            <h1 className="inline-flex items-center">
              <img
                className="py-2 pr-2"
                src="/question-mark.png"
                alt="question"
              />
              CAN I CREATE SUB USERS?
            </h1>
          ),
          content: (
            <p>
              Our panel allows you to create as many sub users as you need, as
              well as set specific permissions per your needs.
            </p>
          ),
        },
      ],
    },
    {
      rows: [
        {
          title: (
            <h1 className="inline-flex items-center">
              <img
                className="py-2 pr-2"
                src="/question-mark.png"
                alt="question"
              />
              CAN I UPGRADE/DOWNGRADE MY SERVER?
            </h1>
          ),
          content: (
            <p>
              Upgrading/Downgrading your server can easily be done at any time
              through your billing panel and the changes are applied to your
              server at once!
            </p>
          ),
        },
      ],
    },
    {
      rows: [
        {
          title: (
            <h1 className="inline-flex items-center">
              <img
                className="py-2 pr-2"
                src="/question-mark.png"
                alt="question"
              />
              HOW CAN I TEST MY PING?
            </h1>
          ),
          content: (
            <p>
              Head over to our Latency Test page to check your ping against all
              of our locations.
            </p>
          ),
        },
      ],
    },
    {
      rows: [
        {
          title: (
            <h1 className="inline-flex items-center">
              <img
                className="py-2 pr-2"
                src="/question-mark.png"
                alt="question"
              />
              CAN SOMEONE DONATE TO MY SERVER?
            </h1>
          ),
          content: (
            <p>
              We use a system called 'Clan Pay' which allows donations directly
              to your account that way you can share the expenses of your gaming
              services.
            </p>
          ),
        },
      ],
    },
    {
      rows: [
        {
          title: (
            <h1 className="inline-flex items-center">
              <img
                className="py-2 pr-2"
                src="/question-mark.png"
                alt="question"
              />
              CAN I TRANSFER MY SERVER TO ANOTHER LOCATION?
            </h1>
          ),
          content: (
            <p>
              Yes, free of charge. Simply open a ticket with us and we will
              proceed with the transfer at your prefered timeslot.
            </p>
          ),
        },
      ],
    },
    {
      rows: [
        {
          title: (
            <h1 className="inline-flex items-center">
              <img
                className="py-2 pr-2"
                src="/question-mark.png"
                alt="question"
              />
              CAN I REQUEST A REFUND?
            </h1>
          ),
          content: (
            <p>
              If you are not satisfied within the first 4 days of your purchase,
              you can request a full refund!
            </p>
          ),
        },
      ],
    },
  ];
  return (
    <div className="w-full flex justify-center md:pt-[88px] pt-[30px] md:mb-20 mb-12">
      <div className="container max-w-[1280px]">
        <h1 className="md:text-[36px] md:text-start text-center text-[30px] uppercase font-[900] text-white px-3">
          faq
        </h1>
        <div className="mb-5 md:text-start text-center">
          <span className="px-3">
            Still have questions? Ask us
            <Link
              to="https://clients.fragnet.net/submitticket.php?language=english&amp;step=2&amp;deptid=2"
              className="text-red-500"
            >
              here
            </Link>
            .
          </span>
        </div>
        <div className="grid md:grid-cols-2">
          <div className="col px-3">
            <FaqWrapper>
              <Faq data={data[0]} />
              <Faq data={data[1]} />
              <Faq data={data[2]} />
              <Faq data={data[3]} />
            </FaqWrapper>
          </div>
          <div className="col px-5">
            <FaqWrapper>
              <Faq data={data[4]} />
              <Faq data={data[5]} />
              <Faq data={data[6]} />
            </FaqWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
