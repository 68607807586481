import React from "react";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { I18n } from "react-redux-i18n";

const AboutUs = () => {
  return (
    <div className="w-full my-0 mx-auto md:px-0 px-3">
      <div className="w-full flex justify-center md:pt-[88px] pt-[30px] md:mb-20 mb-12">
        <div className="container max-w-[1280px] lg:flex mt-24">
          <div className="lg:w-1/3 md:text-right text-center md:pr-8">
            <h1 className="uppercase text-white md:text-[60px] text-[36px] md:leading-[50px] leading-[20px] font-[900]">
              About Us
            </h1>
            <h4 className="text-[24px] text-white uppercase font-[700] ">
              WHO WE ARE
            </h4>
          </div>
          <div className="lg:w-2/3 md:pl-10 pl-3 lg:pr-40 lg:text-start text-center">
            <p>
              Fragnet is a leading provider in online gaming services including
              game servers, dedicated gaming servers and voice over IP
              communication software. Our network infrastructure spans 22 data
              centres worldwide.
            </p>
            <h5 className="text-white font-bold mt-6">
              A leading provider in game servers!
            </h5>
            <p className="mb-8">
              A good network, though, is nothing without quality hardware. Our
              machines use only high end enterprise components. This ensures
              great reliability and performance of every service we offer.
            </p>
            <p className="mb-8">
              Since the beginning we have worked tirelessly to provide a quality
              of service unmatched in our industry, not only in the services we
              offer but in the support we provide to each and every customer.
            </p>
            <h5 className="text-white font-bold">
              Have any questions or need help?
            </h5>
            <div className="mt-5">
              <Link
                to="#"
                className="inline-flex rounded-md z-50 text-center md:mx-0 mx-auto items-center h-[48px] px-[24px] text-[16px] font-[800] bg-[#e90e0e] text-white hover:bg-[#f91d1d] text-white"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full border-t border-[rgba(255,255,255,.12)]">
        <div className="container max-w-[1280px] mx-auto mt-24 lg:flex">
          <div className="lg:w-1/3 md:text-right text-center md:pr-8">
            <h1 className="uppercase text-white text-[36px] leading-[44px] font-[900]">
              WHY CHOOSE FRAGNET?
            </h1>
            <h4 className="text-[24px] text-white uppercase font-[700] mb-12">
              8 REASONS TO CHOOSE US
            </h4>
          </div>
          <div className="lg:w-2/3 md:pl-10 pt-2">
            <div className="grid md:grid-cols-2">
              <div className="col px-3 pb-16">
                <h5 className="flex text-[18px] text-white font-[700]">
                  <img className="mr-8" src="/tech-support.png" alt="tech" />
                  PROFESSIONAL TECHNICAL SUPPORT
                </h5>
                <p className="pt-5">
                  We have staff available 24 hours a day, 7 days a week to
                  provide assistance with all of your game server questions and
                  needs.
                </p>
              </div>
              <div className="col px-3 pb-16">
                <h5 className="flex text-[18px] text-white font-[700]">
                  <img className="mr-8" src="/instant-setup.png" alt="tech" />
                  INSTANT ACCOUNT ACTIVATION
                </h5>
                <p className="pt-5">
                  All game server orders are setup instantly after your payment
                  have been received. The process starts right away and your
                  service will be online within a few minutes.
                </p>
              </div>
              <div className="col px-3 pb-16">
                <h5 className="flex text-[18px] text-white font-[700]">
                  <img
                    className="mr-8"
                    src="/location-switching.png"
                    alt="tech"
                  />
                  FREE LOCATION SWITCHING
                </h5>
                <p className="pt-5">
                  You can switch the geographical location of your game server
                  for free. Head over to our Latency test page to test your ping
                  against all of our locations!
                </p>
              </div>
              <div className="col px-3 pb-16">
                <h5 className="flex text-[18px] text-white font-[700]">
                  <img className="mr-8" src="/hardware.png" alt="tech" />
                  STATE-OF-THE-ART HARDWARE
                </h5>
                <p className="pt-5">
                  We have recently changed all of our Intel E3 CPUs to more
                  powerful ones, such as Intel's E-2288G or i9-9900K as well as
                  AMD's Ryzen 7 3800X! We have also switched from DDR3 to DDR4
                  RAM.
                </p>
              </div>
              <div className="col px-3 pb-16">
                <h5 className="flex text-[18px] text-white font-[700]">
                  <img
                    className="mr-8"
                    src="/gaming-optimized.png"
                    alt="tech"
                  />
                  GAMING OPTIMIZED NETWORK
                </h5>
                <p className="pt-5">
                  By carefully picking networks with low latency and high
                  quality Internet bandwidth - we have the best possible
                  foundation to host fast and reliable game servers.
                </p>
              </div>
              <div className="col px-3 pb-16">
                <h5 className="flex text-[18px] text-white font-[700]">
                  <img className="mr-8" src="/clanpay.png" alt="tech" />
                  CLANPAY AVAILABLE
                </h5>
                <p className="pt-5">
                  Easily have members donate directly to your account. We
                  provide the tool to help you with sharing the expenses of your
                  gaming services.
                </p>
              </div>
              <div className="col px-3 pb-16">
                <h5 className="flex text-[18px] text-white font-[700]">
                  <img
                    className="mr-8"
                    src="/easy-to-use-panel.png"
                    alt="tech"
                  />
                  EASY-TO-USE GAME PANEL
                </h5>
                <p className="pt-5">
                  Stop/Start, install popular mods, create your own scheduled
                  tasks (such as steam update) or reinstall your server, each
                  with just one click! We also offer full FTP access when
                  possible.
                </p>
              </div>
              <div className="col px-3 pb-16">
                <h5 className="flex text-[18px] text-white font-[700]">
                  <img className="mr-8" src="/monitoirng.png" alt="tech" />
                  24/7/365 MONITORING
                </h5>
                <p className="pt-5">
                  With the help of Prometheus, our NOC team is making sure
                  everything is operational and your server runs smoothly!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-full flex justify-center text-white/[.8] py-12"
        style={{
          background: "url('/red-bg.png') center right no-repeat #e90e0e",
        }}
      >
        <div className="container max-w-[1280px] mx-auto xl:flex text-white md:text-start text-center">
          <div className="xl:w-1/3 xl:justify-end justify-center flex items-center md:pr-8">
            <h2 className="uppercase font-[900] pt-1 text-[36px]">
              READY TO ORDER?
            </h2>
          </div>
          <div className="grid xl:mt-0 mt-6 md:grid-cols-4 grid-cols-2 xl:w-2/3">
            <Link to="/page/gameservers" className="col grid">
              <div className="flex justify-center">
                <img src="/game-servers-white.png" alt="Game Servers" />
              </div>
              <h2 className="mt-3 text-[14px] lg:text-[16px] uppercase flex justify-center items-center">
                {I18n.t("gameServers")}
                <img
                  src="/service-arrow-white.png"
                  alt="Service Arrow"
                  className="ml-2 py-2"
                />
              </h2>
            </Link>
            <Link to="/page/discord-bots" className="col grid">
              <div className="flex justify-center">
                <img src="/shared-hosting-white.png" alt="Discord Bots" />
              </div>
              <h2 className="mt-3 text-[14px] lg:text-[16px] uppercase flex justify-center items-center">
                {I18n.t("discordBots")}
                <img
                  src="/service-arrow-white.png"
                  alt="Service Arrow"
                  className="ml-2 py-2"
                />
              </h2>
            </Link>
            <Link to="/page/voiceservers" className="col grid">
              <div className="flex justify-center">
                <img src="/voice-servers-white.png" alt="Voice Servers" />
              </div>
              <h2 className="mt-3 text-[14px] lg:text-[16px] uppercase flex justify-center items-center">
                {I18n.t("voiceServers")}
                <img
                  src="/service-arrow-white.png"
                  alt="Service Arrow"
                  className="ml-2 py-2"
                />
              </h2>
            </Link>
            <Link to="#" className="col grid">
              <div className="flex justify-center">
                <img
                  src="/dedicated-servers-white.png"
                  alt="Dedicated Servers"
                />
              </div>
              <h2 className="mt-3 text-[14px] lg:text-[16px] uppercase flex justify-center items-center">
                {I18n.t("dedicatedServers")}
                <img
                  src="/service-arrow-white.png"
                  alt="Service Arrow"
                  className="ml-2 py-2"
                />
              </h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center py-20">
        <div className="container max-w-[1280px] mx-auto lg:flex">
          <div className="lg:w-1/3 md:text-end text-center md:pr-8">
            <h2 className="uppercase font-[900] pt-1 leading-[40px] text-white text-[36px]">
              PARTNERS
            </h2>
            <h4 className="text-[24px] text-white uppercase font-[700] mb-12">
              WE'RE A TRUSTED PROVIDER
            </h4>
          </div>
          <div className="lg:w-2/3 md:pl-10 md:pr-3">
            <div className="w-full h-full border border-[rgba(255,255,255,.12)] rounded-md">
              <div className="grid xl:grid-cols-3 md:grid-cols-2 border-b border-[rgba(255,255,255,.12)]">
                <div className="col-span-1 md:border-r border-[rgba(255,255,255,.12)] flex items-center">
                  <div className="mx-auto md:mt-0 mt-6">
                    <img src="/ealogo.png" alt="EAlogo" />
                  </div>
                </div>
                <div className="xl:col-span-2 md:text-start text-center col-span-1 px-7 py-7">
                  <h1 className="uppercase text-[18px] leading-[28px] text-white font-[700]">
                    BATTLEFIELD
                  </h1>
                  <p>
                    Exclusivly hosting major Battlefield titles released between
                    2011 and 2015.
                  </p>
                </div>
              </div>
              <div className="grid xl:grid-cols-3 md:md:grid-cols-2 border-b border-[rgba(255,255,255,.12)]">
                <div className="col-span-1 md:border-r border-[rgba(255,255,255,.12)] flex items-center">
                  <div className="mx-auto md:mt-0 mt-6">
                    <img src="/bohemia.png" alt="EAlogo" />
                  </div>
                </div>
                <div className="xl:col-span-2 md:text-start text-center col-span-1 px-7 py-7">
                  <h1 className="uppercase text-[18px] leading-[28px] text-white font-[700]">
                    BOHEMIA INTERACTIVE
                  </h1>
                  <p>
                    Hosting Official DayZ servers in the South America region.
                  </p>
                </div>
              </div>
              <div className="grid xl:grid-cols-3 md:grid-cols-2 border-b border-[rgba(255,255,255,.12)]">
                <div className="col-span-1 md:border-r border-[rgba(255,255,255,.12)] flex items-center">
                  <div className="mx-auto md:mt-0 mt-6">
                    <img src="/esllogo.png" alt="EAlogo" />
                  </div>
                </div>
                <div className="xl:col-span-2 md:text-start text-center col-span-1  px-7 py-7">
                  <h1 className="uppercase text-[18px] leading-[28px] text-white font-[700]">
                    ESL
                  </h1>
                  <p>
                    ESL takes advantage of our on-demand game server platform to
                    host CS:GO servers for their Open Cups hosted in Europe.
                  </p>
                </div>
              </div>
              <div className="grid xl:grid-cols-3 md:grid-cols-2 border-b border-[rgba(255,255,255,.12)]">
                <div className="col-span-1 md:border-r border-[rgba(255,255,255,.12)] flex items-center">
                  <div className="mx-auto md:mt-0 mt-6">
                    <img src="/Esportal_logo2.png" alt="EAlogo" />
                  </div>
                </div>
                <div className="xl:col-span-2 md:text-start text-center col-span-1  px-7 py-7">
                  <h1 className="uppercase text-[18px] leading-[28px] text-white font-[700]">
                    ESPORTAL
                  </h1>
                  <p>
                    Esportal is a web-based competitive arena for CS:GO. Through
                    our partnership we provide lag-free servers across the
                    globe.
                  </p>
                </div>
              </div>
              <div className="grid xl:grid-cols-3 md:grid-cols-2">
                <div className="col-span-1 md:border-r border-[rgba(255,255,255,.12)] flex items-center">
                  <div className="mx-auto md:mt-0 mt-6">
                    <img src="/everyday_hero1.png" alt="EAlogo" />
                  </div>
                </div>
                <div className="xl:col-span-2 md:text-start text-center col-span-1  px-7 py-7">
                  <h1 className="uppercase text-[18px] leading-[28px] text-white font-[700]">
                    EVERYDAY HE.RO
                  </h1>
                  <p>
                    Everyday He.ro on Minecraft is an exciting and important
                    initiative where we both educates and empowers children to
                    become a “Caring buddy” that helps other children and young
                    people. Behind the initiative is the non-profit organization
                    Suicide Rescue.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
