import React from "react";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Careers = () => {
  return (
    <div className="w-full my-0 mx-auto md:px-0 px-3">
      <div className="w-full flex justify-center md:pt-[88px] pt-[30px] md:mb-20 mb-12">
        <div className="container max-w-[1280px] lg:flex mt-24">
          <div className="lg:w-1/3 md:text-right text-center md:pr-8">
            <h1 className="uppercase text-white md:text-[60px] text-[36px] md:leading-[50px] leading-[20px] font-[900]">
              Work with us!
            </h1>
          </div>
          <div className="lg:w-2/3 md:pl-10 pl-3 lg:pr-40 lg:text-start text-center">
            <p className="mb-8">
              Fragnet is rapidly growing, and we are now looking for
              experienced, technically minded, friendly individuals that will
              work well on the team. Customer satisfaction is where we excel,
              along with high-quality services.
            </p>
            <p className="mb-8">
              Our game server hosting platform and support backend are all
              carefully structured, and we have very specific and fair policies
              that are easy to understand, follow and clarify to clients. You
              can understand what products we offer and which you will have to
              support on our website.
            </p>
            <p className="mb-8">
              We are always looking for talented people to join our team. If you
              are interested in working with us, please send an email to{" "}
              <Link to="mailto:jobs@fragnet.net" className="text-red-500">
                jobs@fragnet.net
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div className="w-full border-t border-[rgba(255,255,255,.12)]">
        <div className="container max-w-[1280px] mx-auto my-24 lg:flex">
          <div className="lg:w-1/3 md:text-right text-center md:pr-8">
            <h1 className="uppercase text-white text-[36px] leading-[44px] font-[900]">
              Benefits
            </h1>
          </div>
          <div className="lg:w-2/3 md:pl-10 pl-3 lg:pr-40 lg:text-start text-center">
            <h5 className="text-white font-bold">Goodies:</h5>
            <p>
              - Free Nitro subscription!
              <br />- New Steam games, you can never have enough of them!
            </p>
            <h5 className="text-white font-bold mt-6">Hosting:</h5>
            <p className="mb-8">
              As a member of our team, you will be able to host game servers
              and/or your personal 'homelab', free of charge.
            </p>
            <h5 className="text-white font-bold">Extra:</h5>
            <p className="mb-8">
              - You get a free YubiKey which you can use for both personal and
              work accounts.
              <br />
              - Cool merch to keep you warm during the winter and cool during
              the summer!
              <br />
              - Our team is spread across the world, so you can work from
              anywhere.
              <br />- Flexible working hours.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full border-t border-[rgba(255,255,255,.12)]">
        <div className="container max-w-[1280px] mx-auto my-24 lg:flex">
          <div className="lg:w-1/3 md:text-right text-center md:pr-8">
            <h1 className="uppercase text-white text-[36px] leading-[44px] font-[900]">
              REQUIREMENTS
            </h1>
          </div>
          <div className="lg:w-2/3 md:pl-10 pl-3 lg:pr-40 lg:text-start text-center">
            <h5 className="text-white font-bold">You have...</h5>
            <p className="mb-8">
              - Excellent English written skill. <br />
              - Ability to work well in a team.
              <br />
              - Excellent customer service and communication skills.
              <br />
              - Experience with TCAdmin and Pterodactyl.
              <br />
              - Good technical knowledge of Windows and Ubuntu.
              <br />
              - Ability to multi-task and meet goals set and improve skills
              where directed.
              <br />- Ability to troubleshoot technical issues that users are
              experiencing.
            </p>
            <p className="mb-8">
            This is a paid position, please state your requirement in your application!
            </p>
            <h5 className="text-white font-bold">Apply today by sending us your CV!</h5>
            <div className="mt-5">
              <Link
                to="mailto:jobs@fragnet.net"
                className="inline-flex rounded-md z-50 text-center md:mx-0 mx-auto items-center h-[48px] px-[24px] text-[16px] font-[800] bg-[#e90e0e] text-white hover:bg-[#f91d1d] text-white"
              >
                Apply
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Careers;
