import React from "react";
import Button from "../components/Button";
import Footer from "../components/Footer";


const VoiceServer: React.FC = () => {
  return (
    <div className={"w-full my-0 mx-auto"}>
      <section className="relative py-20 pt-48 h-auto pb-0 bg-center bg-no-repeat bg-cover">
        <div className="max-w-7xl my-0 mx-auto px-4 w-full sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl">
          <div className="flex flex-wrap mx-[-15px]">
            <div className={"flex-grow text-center"}>
              <h1
                className={"text-6xl uppercase font-bold my-0 text-neutral-200"}
              >
                Voice Server
              </h1>
              <h2
                className={
                  "text-[18px] text-gray-200 mx-auto max-w-4xl leading-7 relative mt-12 z-10 font-medium mb-32"
                }
              >
                <p>
                  You get complete control over your Voice service and it's all
                  in one place! Our Voice servers are designed to meet needs
                  from small clans to massive communities or businesses!
                </p>
                <p>
                  The concept behind our voice based services is quite advanced
                  to ensure that your Voice service runs without issue. With our
                  advanced monitoring system we monitor every aspect of the
                  environment that the Voice services run on to ensure that
                  other users services will not affect your services.
                </p>
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section className="game-pricing py-20 text-center">
        <div className="max-w-7xl my-0 mx-auto px-4 w-full sm:max-w-md md:max-w-lg lg:max-w-5xl xl:max-w-7xl">
          <div className="text-center">
            <h2 className="mb-2 mt-2 font-bold font-mono uppercase text-3xl text-white inline-block">
              SIMPLE & AFFORDABLE PRICING
            </h2>
            <div className="bg-red-500 h-1 w-1/4 mx-auto my-2 mb-10"></div>
          </div>
          <div className="flex flex-wrap mx-[-15px] gap-y-4 items-center justify-center">
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 mb-4 relative text-slate-100">
              <div
                className={`relative rounded overflow-hidden shadow-lg transform hover:-translate-y-4 transition-transform duration-300 border-4 border-slate-800`}
              >
                <div className="p-4 bg-slate-800 text-white shadow-md border-red-500 mb-4">
                  <h2 className="font-bold uppercase text-2xl">DISCORDGSM</h2>
                </div>
                <small className="font-light text-xl text-slate-400 line-through mr-2 mt-4">
                  $0.69/month
                </small>
                <div className="flex justify-center">
                  <h3 className="text-3xl font-extrabold mt-2 mb-1">$0.5</h3>
                  <small className="font-medium text-lg text-slate-400 pt-4">
                    /month
                  </small>
                </div>
                <div className={"flex justify-center items-center"}>
                  <ul className="pt-2 text-center">
                    <li className="my-1 pb-1 flex items-center">
                      <div>
                        <span className={"font-bold ml-4 mr-2"}>Instant</span>
                        <span>Setup</span>
                      </div>
                    </li>
                    <li className="my-1 pb-1 flex items-center">
                      <div>
                        <span className={"font-bold ml-4 mr-2"}>Custom</span>
                        <span>Control Panel</span>
                      </div>
                    </li>
                    <li className="my-1 pb-1 flex items-center">
                      <div>
                        <span className={"font-bold ml-4 mr-2"}>Full</span>
                        <span>File Access</span>
                      </div>
                    </li>
                    <li className="my-1 pb-1 flex items-center">
                      <div>
                        <span className={"font-bold ml-4 mr-2"}>MySQL</span>
                        <span>Database</span>
                      </div>
                    </li>
                    <li className="my-1 pb-1 flex items-center">
                      <div>
                        <span className={"font-bold ml-4 mr-2"}>Offsite</span>
                        <span>Backups</span>
                      </div>
                    </li>
                    <li className="my-1 pb-1 flex items-center">
                      <div>
                        <span className={"font-bold ml-4 mr-2"}>SSD/NVMe</span>
                        <span>Disk</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <Button
                  color={"red"}
                  text={"Order Now"}
                  className="transition-transform transform hover:scale-105 mb-6"
                />
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 mb-4 relative text-slate-100">
              <div
                className={`relative rounded overflow-hidden shadow-lg transform hover:-translate-y-4 transition-transform duration-300 border-4 border-slate-800`}
              >
                <div className="p-4 bg-slate-800 text-white shadow-md border-red-500 mb-4">
                  <h2 className="font-bold uppercase text-2xl">COREBOT</h2>
                </div>
                <small className="font-light text-xl text-slate-400 line-through mr-2 mt-4">
                  $0.69/month
                </small>
                <div className="flex justify-center">
                  <h3 className="text-3xl font-extrabold mt-2 mb-1">$0.5</h3>
                  <small className="font-medium text-lg text-slate-400 pt-4">
                    /month
                  </small>
                </div>
                <div className={"flex justify-center items-center"}>
                  <ul className="pt-2 text-center">
                    <li className="my-1 pb-1 flex items-center">
                      <div>
                        <span className={"font-bold ml-4 mr-2"}>Instant</span>
                        <span>Setup</span>
                      </div>
                    </li>
                    <li className="my-1 pb-1 flex items-center">
                      <div>
                        <span className={"font-bold ml-4 mr-2"}>Custom</span>
                        <span>Control Panel</span>
                      </div>
                    </li>
                    <li className="my-1 pb-1 flex items-center">
                      <div>
                        <span className={"font-bold ml-4 mr-2"}>Full</span>
                        <span>File Access</span>
                      </div>
                    </li>
                    <li className="my-1 pb-1 flex items-center">
                      <div>
                        <span className={"font-bold ml-4 mr-2"}>MySQL</span>
                        <span>Database</span>
                      </div>
                    </li>
                    <li className="my-1 pb-1 flex items-center">
                      <div>
                        <span className={"font-bold ml-4 mr-2"}>Offsite</span>
                        <span>Backups</span>
                      </div>
                    </li>
                    <li className="my-1 pb-1 flex items-center">
                      <div>
                        <span className={"font-bold ml-4 mr-2"}>SSD/NVMe</span>
                        <span>Disk</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <Button
                  color={"red"}
                  text={"Order Now"}
                  className="transition-transform transform hover:scale-105 mb-6"
                />
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 mb-4 relative text-slate-100">
              <div
                className={`relative rounded overflow-hidden shadow-lg transform hover:-translate-y-4 transition-transform duration-300 border-4 border-slate-800`}
              >
                <div className="p-4 bg-slate-800 text-white shadow-md border-red-500 mb-4">
                  <h2 className="font-bold uppercase text-2xl">SINUSBOT</h2>
                </div>
                <small className="font-light text-xl text-slate-400 line-through mr-2 mt-4">
                  $0.69/month
                </small>
                <div className="flex justify-center">
                  <h3 className="text-3xl font-extrabold mt-2 mb-1">$0.5</h3>
                  <small className="font-medium text-lg text-slate-400 pt-4">
                    /month
                  </small>
                </div>
                <div className={"flex justify-center items-center"}>
                  <ul className="pt-2 text-center">
                    <li className="my-1 pb-1 flex items-center">
                      <div>
                        <span className={"font-bold ml-4 mr-2"}>Instant</span>
                        <span>Setup</span>
                      </div>
                    </li>
                    <li className="my-1 pb-1 flex items-center">
                      <div>
                        <span className={"font-bold ml-4 mr-2"}>Custom</span>
                        <span>Control Panel</span>
                      </div>
                    </li>
                    <li className="my-1 pb-1 flex items-center">
                      <div>
                        <span className={"font-bold ml-4 mr-2"}>Full</span>
                        <span>File Access</span>
                      </div>
                    </li>
                    <li className="my-1 pb-1 flex items-center">
                      <div>
                        <span className={"font-bold ml-4 mr-2"}>MySQL</span>
                        <span>Database</span>
                      </div>
                    </li>
                    <li className="my-1 pb-1 flex items-center">
                      <div>
                        <span className={"font-bold ml-4 mr-2"}>Offsite</span>
                        <span>Backups</span>
                      </div>
                    </li>
                    <li className="my-1 pb-1 flex items-center">
                      <div>
                        <span className={"font-bold ml-4 mr-2"}>SSD/NVMe</span>
                        <span>Disk</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <Button
                  color={"red"}
                  text={"Order Now"}
                  className="transition-transform transform hover:scale-105 mb-6"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default VoiceServer;
