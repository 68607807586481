import {Variable} from "../../types";
import { Field } from 'formik';
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import Switch from "react-switch";
import {ErrorMessage} from "formik";
import {I18n} from "react-redux-i18n";

type VariableConfigProps = {
    variables: Variable[];
    values: any;
    setFieldValue: (fieldName: string, value: any) => void;
}

type VariableProps = {
    variable: Variable;
    value?: any;
    setFieldValue?: (fieldName: string, value: any) => void;
}

const StringShortVariable = ({variable}: VariableProps) => {
    return (
        <div className="mb-8">
            <h4 className="text-[18px] font-[500] mb-1">
                {variable.name}
                {Boolean(variable.required) && <span className={'ms-2 text-red-700'}>*</span>}
            </h4>
            {variable.description && (
                <p className={'text-sm text-gray-500 mb-1'}>
                    <FontAwesomeIcon icon={faQuestionCircle} color={'#0e8ee9'} /> {variable.description}
                </p>
            )}
            <Field
                name={variable.identifier}
                className="h-[48px] py-[12px] border-[#2D3747] border text-[18px] rounded-[5px] w-full bg-[#0E1623]/0 px-[10px] outline-none text-white focus:border-[#E90E0E]"
                type="text"
            />
            <div className={'text-sm text-red-500'}>
                <ErrorMessage name={variable.identifier} />
            </div>
        </div>
    );
}

const StringLongVariable = ({variable}: VariableProps) => {
    return (
        <div className="mb-8">
            <h4 className="text-[18px] font-[500] mb-1">
                {variable.name}
                {Boolean(variable.required) && <span className={'ms-2 text-red-700'}>*</span>}
            </h4>
            {variable.description && (
                <p className={'text-sm text-gray-500 mb-1'}>
                    <FontAwesomeIcon icon={faQuestionCircle} color={'#0e8ee9'} /> {variable.description}
                </p>
            )}
            <Field
                as={'textarea'}
                name={variable.identifier}
                rows={3}
                className="py-[12px] border-[#2D3747] border text-[18px] rounded-[5px] w-full bg-[#0E1623]/0 px-[10px] outline-none text-white focus:border-[#E90E0E]"
            />
            <div className={'text-sm text-red-500'}>
                <ErrorMessage name={variable.identifier} />
            </div>
        </div>
    );
}

const NumberInputVariable = ({variable}: VariableProps) => {
    return (
        <div className="mb-8">
            <h4 className="text-[18px] font-[500] mb-1">
                {variable.name}
                {Boolean(variable.required) && <span className={'ms-2 text-red-700'}>*</span>}
            </h4>
            {variable.description && (
                <p className={'text-sm text-gray-500 mb-1'}>
                    <FontAwesomeIcon icon={faQuestionCircle} color={'#0e8ee9'} /> {variable.description}
                </p>
            )}
            <Field
                type={'number'}
                name={variable.identifier}
                className="h-[48px] py-[12px] border-[#2D3747] border text-[18px] rounded-[5px] w-full bg-[#0E1623]/0 px-[10px] outline-none text-white focus:border-[#E90E0E]"
            />
            <div className={'text-sm text-red-500'}>
                <ErrorMessage name={variable.identifier} />
            </div>
        </div>
    );
}

const NumberSlideVariable = ({variable, value, setFieldValue}: VariableProps) => {
    return (
        <>
            {variable.min_value && variable.max_value && (
                <div className="mb-8">
                    <h4 className="text-[18px] font-[500] mb-1">
                        {variable.name}
                        {Boolean(variable.required) && <span className={'ms-2 text-red-700'}>*</span>}
                    </h4>
                    {variable.description && (
                        <p className={'text-sm text-gray-500 mb-1'}>
                            <FontAwesomeIcon icon={faQuestionCircle} color={'#0e8ee9'} /> {variable.description}
                        </p>
                    )}
                    <Slider
                        handleRender={(node, handleProps) => {
                            return (
                                <Tooltip
                                    overlayInnerStyle={{ minHeight: "auto" }}
                                    overlay={`Value : ${handleProps.value}`}
                                    placement="top"
                                >
                                    {node}
                                </Tooltip>
                            );
                        }}
                        styles={{
                            track: {backgroundColor: "#e90e0e", height: "11px"},
                            rail: {backgroundColor: "rgba(14,22,35)", height: "11px"},
                            handle: {
                                borderColor: "rgba(233,14,14,0.57)",
                                height: 22,
                                width: 22,
                                marginTop: "-6px",
                                backgroundColor: "#292f3a",
                                opacity: 1,
                            }
                        }}
                        min={Number(variable.min_value)}
                        max={Number(variable.max_value)}
                        step={Number(variable.step_value)}
                        value={Number(value)}
                        onChange={(value) => setFieldValue && setFieldValue(variable.identifier, value)}
                    />
                    <div className={'text-sm text-red-500'}>
                        <ErrorMessage name={variable.identifier} />
                    </div>
                </div>
            )}
        </>
    );
}

const BooleanVariable = ({variable, value, setFieldValue}: VariableProps) => {
    return (
        <div className="mb-8">
            <h4 className="text-[18px] font-[500] mb-1">
                {variable.name}
                {Boolean(variable.required) && <span className={'ms-2 text-red-700'}>*</span>}
            </h4>
            {variable.description && (
                <p className={'text-sm text-gray-500 mb-1'}>
                    <FontAwesomeIcon icon={faQuestionCircle} color={'#0e8ee9'} /> {variable.description}
                </p>
            )}
            <Switch
                onChange={() => setFieldValue && setFieldValue(variable.identifier, !value)}
                checked={Boolean(value)}
                onColor="#e90e0e"
                onHandleColor="#292f3b"
                handleDiameter={25}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={30}
                width={60}
            />
            <div className={'text-sm text-red-500'}>
                <ErrorMessage name={variable.identifier} />
            </div>
        </div>
    );
}

const SelectVariable = ({variable, value}: VariableProps) => {
    const options = variable.selectable_values?.split(",") || [];

    return (
        <div className="mb-8">
            <h4 className="text-[18px] font-[500] mb-1">
                {variable.name}
                {Boolean(variable.required) && <span className={'ms-2 text-red-700'}>*</span>}
            </h4>
            {variable.description && (
                <p className={'text-sm text-gray-500 mb-1'}>
                    <FontAwesomeIcon icon={faQuestionCircle} color={'#0e8ee9'} /> {variable.description}
                </p>
            )}
            <Field
                name={variable.identifier}
                as={'select'}
                className="h-[48px] py-[12px] border-[#2D3747] border text-[18px] rounded-[5px] w-full bg-[#0E1623]/0 px-[10px] outline-none text-white focus:border-[#E90E0E]"
            >
                {options.map((option, index) => <option key={index} value={option}>{option}</option>)}
            </Field>
            <div className={'text-sm text-red-500'}>
                <ErrorMessage name={variable.identifier} />
            </div>
        </div>
    );
}

const VariableConfig = ({variables, values, setFieldValue}: VariableConfigProps) => {
    return (
        <>
            {variables && variables.length > 0 && (
                <div className={'mb-12'}>
                    <div>
                        <h1 className="inline-flex uppercase font-proxima-bold text-2xl text-white items-center">
                            <div
                                className="h-[44px] text-center skew-x-[-15deg] leading-[44px] w-[48px] text-white rounded-[6px] bg-[#e90e0e] bg-[linear-gradient(134deg,#e90e0e_0%,#e90e0e_100%)] shadow-[0_2px_4px_0_rgba(0,0,0,0.69),inset_0_-2px_2px_0_rgba(55,0,0,0.20)] uppercase text-[24px] font-[600] mr-[24px]">
                                <div className="skew-x-[15deg]">
                                    <span>3</span>
                                </div>
                            </div>
                            {I18n.t('serverSettings')}
                        </h1>
                    </div>
                    <div className="mt-12 mb-12">
                        {variables.map((variable, index) => (
                            variable.type === 'string_short' ? (
                                <StringShortVariable key={index} variable={variable}/>
                            ) : variable.type ==='string_long'? (
                                <StringLongVariable key={index} variable={variable}/>
                            ) : variable.type === 'number_input'? (
                                <NumberInputVariable key={index} variable={variable}/>
                            ) : variable.type === 'number_slide'? (
                                <NumberSlideVariable key={index} variable={variable} value={values[variable.identifier]} setFieldValue={setFieldValue} />
                            ) : variable.type === 'boolean'? (
                                <BooleanVariable key={index} variable={variable} value={values[variable.identifier]} setFieldValue={setFieldValue} />
                            ) : variable.type ==='select'? (
                                <SelectVariable key={index} variable={variable} value={values[variable.identifier]} />
                            ) : null
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}

export default VariableConfig;