import React, {useState, useEffect, useRef, useCallback} from "react";
import {Location} from "../../types";
import "./Location.css";
import styled from "styled-components";
import {ErrorMessage} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsRotate} from "@fortawesome/free-solid-svg-icons";
// @ts-ignore
import {kebabCase} from "lodash";
import {I18n} from "react-redux-i18n";

interface Latency {
    [key: string]: number[];
}

interface CheckingLatency {
    [key: string]: boolean;
}

type LocationProps = {
    locations: Location[];
    locationValue: number;
    setFieldValue: (field: string, value: number) => void;
}

const LocationWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(209px, 1fr));
  gap: 20px;
  padding: 20px;

  & .location-card {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #0d1522; 
    color: #fff; 
    border-radius: 8px;
    border-color: #28364c;
  }

  & .flag {
    flex-shrink: 0; /* Prevent the flag from shrinking */
    margin-right: 10px;
  }

  & .latency-container {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 10px;
  }

  & .location-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  & .city-name {
    font-weight: bold;
  }

  & .country-name {
    font-size: 0.8rem;
    color: #aaa;
  }

  & .item-selected {
    border: 2px solid #e90e0e;
  }
`;

const LatencyCheckPage = ({ locations, locationValue, setFieldValue }: LocationProps) => {
    const [latency, setLatency] = useState<Latency>();
    const [checkingLatency, setCheckingLatency] = useState<CheckingLatency>({});
    const webSocketRefs = useRef<WebSocket[]>([]);
    const [latencyCheckRunning, setLatencyCheckRunning] = useState(true);

    const initiatePingChecks = useCallback(() => {
        webSocketRefs.current.forEach((ws) => ws && ws.readyState === WebSocket.OPEN && ws.close());
        webSocketRefs.current = [];

        const newCheckingLatency: CheckingLatency = {};
        setLatencyCheckRunning(true);
        locations.forEach(location => {
            newCheckingLatency[location.ping_site] = true;

            const ws = new WebSocket(location.ping_site);
            webSocketRefs.current.push(ws);

            let pingCount = 0;
            let latencies: number[] = [];
            let startTime: number;
            let pingIntervalId: NodeJS.Timeout;

            ws.onopen = () => {
                pingIntervalId = setInterval(() => {
                    if (pingCount < 3 && ws.readyState === WebSocket.OPEN) {
                        startTime = Date.now(); // Set startTime before sending a ping
                        ws.send(JSON.stringify({ cmd: "ping" }));
                        pingCount++;
                    } else {
                        clearInterval(pingIntervalId); // Clear the interval after 3 pings
                    }
                }, 500); // Send a ping every 500ms
            };

            ws.onmessage = (e) => {
                const data = JSON.parse(e.data);
                if (data.cmd === "pong") {
                    const latencyTime = Math.round(Date.now() - startTime);
                    latencies.push(latencyTime);

                    if (pingCount === 3) {
                        clearInterval(pingIntervalId); // Ensure interval is cleared
                        const minLatency = Math.min(...latencies);
                        setLatency(prevLatency => {
                            const updatedLatency = prevLatency || {};
                            updatedLatency[location.ping_site] = [minLatency]; // Assign the least latency
                            return updatedLatency;
                        });
                        setCheckingLatency(prev => ({
                            ...prev,
                            [location.ping_site]: false
                        }));
                        ws.close();
                    }
                }
            };

            ws.onclose = () => {
                clearInterval(pingIntervalId); // Clear the interval when the WebSocket is closed
            };
        });

        // Reset the states
        setLatency({});
        setLatencyCheckRunning(false);
        setCheckingLatency(newCheckingLatency);
    }, [locations]);

    useEffect(() => {
        if (!locations) return;
        initiatePingChecks();
    }, [locations, initiatePingChecks]);

    const sortedLocations = locations.slice().sort((a, b) => {
        // Check if latency is defined and has the necessary keys
        const latenciesA = latency && latency[a.ping_site] ? latency[a.ping_site] : null;
        const latenciesB = latency && latency[b.ping_site] ? latency[b.ping_site] : null;

        const avgLatencyA = latenciesA && latenciesA.length
            ? latenciesA.reduce((acc, val) => acc + val, 0) / latenciesA.length
            : Number.MAX_VALUE;

        const avgLatencyB = latenciesB && latenciesB.length
            ? latenciesB.reduce((acc, val) => acc + val, 0) / latenciesB.length
            : Number.MAX_VALUE;

        return avgLatencyA - avgLatencyB;
    });

    return (
        <div className="relative h-auto mb-12">
            <div className="mb-5">
                <h1 className="inline-flex uppercase font-proxima-bold text-2xl text-white items-center">
                    <div
                        className="h-11 skew-x-[-15deg] text-center leading-[44px] w-12 text-white rounded-[6px] bg-[#e90e0e] bg-[linear-gradient(134deg,#e90e0e_0%,#e92e1e_100%)] shadow-[0_2px_4px_0_rgba(0,0,0,0.69),inset_0_-2px_2px_0_rgba(55,0,0,0.20)] uppercase text-2xl font-proxima-bold mr-6">
                        <div className="skew-x-[15deg]"><span>1</span></div>
                    </div>
                    {I18n.t('chooseLocation')}
                    <button
                        onClick={initiatePingChecks}
                        disabled={latencyCheckRunning}
                    >
                        <FontAwesomeIcon icon={faArrowsRotate} className={'h-5 w-5'} />
                    </button>
                </h1>
            </div>
            <LocationWrapper>
                {sortedLocations.map((location, i) => {
                    const latencies = latency?.[location.ping_site] ?? [];
                    const avgLatency = latencies.length
                        ? Math.round(latencies.reduce((acc, val) => acc + val, 0) / latencies.length)
                        : null;

                    let latencyColorClass = 'bg-yellow-600 border-yellow-400';
                    if (avgLatency !== null) {
                        if (avgLatency < 80) {
                            latencyColorClass = 'bg-green-600 border-green-400';
                        } else if (avgLatency >= 110) {
                            latencyColorClass = 'bg-red-600 border-red-400';
                        }
                    }

                    return (
                        <div
                            key={i}
                            className={`location-card ${locationValue === location.id ? 'item-selected' : ''}`}
                            onClick={() => setFieldValue('location', location.id)}
                        >
                            <img
                                src={`/flags/${kebabCase(location.country)}.svg`}
                                alt={location.country}
                                className="flag"
                                width="32"
                                height="32"
                            />
                            <div className="location-info">
                                <span className="city-name">{location.city}</span>
                                <span className="country-name">{location.country}</span>
                            </div>
                            <div className="latency-container">
                                <div className={`flex h-6 select-none items-center justify-center border-2 text-xs font-semibold text-white ${latencyColorClass}`} style={{ transform: 'skewX(-10deg)' }}>
                                    <span className="px-1">
                                        {avgLatency !== null
                                            ? `${avgLatency}ms`
                                            : checkingLatency[location.ping_site]
                                                ? "Checking..."
                                                : "No Data"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </LocationWrapper>
            <div className={'text-sm text-red-500'}>
                <ErrorMessage name={'location'} />
            </div>
        </div>
    );
}

export default LatencyCheckPage;
