import {faDiamond} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import {Link} from "react-router-dom";

export type MenuItem = {
    label: string | JSX.Element;
    value?: string|number;
    url?: string;
    onChange?: (value: any) => void;
};

type VerticalDropdownMenuProps = {
    menuName: string | JSX.Element;
    items: MenuItem[];
};

const VerticalDropdownMenu: React.FC<VerticalDropdownMenuProps> = ({items, menuName}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleItemClick = () => {
        setTimeout(setIsOpen, 200, false);
    };

    return (
        <li
            className="hidden lg:inline-block flex-row items-center px-[10px] relative align-middle"
            onBlur={() => {
                setTimeout(setIsOpen, 200, false);
            }}
        >
            <button type="button" className="flex flex-row items-center p-0" onClick={handleToggle}>
                <span
                    className={`font-proxima-nova text-[13px] uppercase font-bold ${
                        isOpen ? "text-red-700" : "text-white"
                    } flex flex-row items-center p-0`}
                >
                    {menuName}
                </span>
                <span className="w-4 h-4 transform rotate-270 ml-1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className={`text-${
                            isOpen ? "red-700 font-bold" : "white font-medium"
                        }`}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                        />
                    </svg>
                </span>
            </button>
            {isOpen && (
                <div
                    className="absolute top-5 z-16 mt-[15px] ml-[-15px] w-40 bg-[#0d111b] rounded-md shadow-lg border-[6px] border-[#151d31] min-w-[200px]">
                    <FontAwesomeIcon
                        icon={faDiamond}
                        color="white"
                        style={{
                            height: "14px",
                            width: "14px",
                            left: "10%",
                            top: "-15px",
                            position: "absolute",
                        }}
                    />
                    {items.map((item, index) => (
                        <>
                            {item.url ? (
                                <Link
                                    key={index}
                                    to={item.url}
                                    className="block px-4 py-2 text-white font-proxima-nova font-medium text-[16px] hover:bg-[#e90f0e66] hover:border-spacing-1 hover:rounded-sm"
                                    onClick={handleItemClick}
                                >
                                    {item.label}
                                </Link>
                            ) : item.onChange ? (
                                <div
                                    key={index}
                                    className="block cursor-pointer px-4 py-2 text-white font-proxima-nova font-medium text-[16px] hover:bg-[#e90f0e66] hover:border-spacing-1 hover:rounded-sm"
                                    onClick={() => item.onChange && item.onChange(item.value)}
                                >{item.label}</div>
                            ) : (
                                <div key={index} className="block cursor-pointer px-4 py-2 text-white font-proxima-nova font-medium text-[16px] hover:bg-[#e90f0e66] hover:border-spacing-1 hover:rounded-sm">{item.label}</div>
                            )}
                        </>
                    ))}
                </div>
            )}
        </li>
    );
};

export default VerticalDropdownMenu;
