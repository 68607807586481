import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import styled from "styled-components";
import {getGame, getGameVariables, getLocations, getPackage, getParams, getPrice, getServerType} from "../../utils";
import Footer from "../../components/Footer";
import LocationFields from "./Location";
import RightSide from "./RightSide";
import {useParams} from "react-router-dom";
import classNames from "classnames";
import Capability from "./Capability";
import Billing from "./Billing";
import {useQuery} from "@tanstack/react-query";
import Variables from "./Variables";
import {Form, Formik, FormikHelpers, useFormikContext} from "formik";
import {number, object, ObjectShape, string} from "yup";
import {Game, ServerType, Variable, Location, Package} from "../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {useAppSelector} from "../../hooks";
import {baseConfigs} from "../../configs";

interface BodyProps {
    background?: string;
}

const BodyWrapper = styled.div<BodyProps>`
  position: relative;
  background: none;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-height: 600px;
    height: 100%;
    background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(12, 16, 26, 1) 100%
    ) top center no-repeat,
    url("${(props) => props.background}") top center no-repeat;
    background-size: cover;
    width: 100%;
    z-index: -1;
  }
`;


type OrderFormProps = {
    game: Game,
    locations: Location[];
    variables: Variable[];
    serverTypes: ServerType[];
    gamePackage: Package|undefined;
    schema: ObjectShape;
    initialValues: object;
}

type FormContainerProps = {
    values: any,
    game: Game,
    locations: Location[];
    variables: Variable[];
    serverTypes: ServerType[];
    gamePackage: Package|undefined;
    selectedLocation: Location|undefined;
    selectedServerType: ServerType|undefined;
    setSelectedLocation: Dispatch<SetStateAction<Location|undefined>>;
    setSelectedServerType: Dispatch<SetStateAction<ServerType|undefined>>;
    paramsLoading: boolean;
    paramsError: Error|null;
    setFieldValue: any;
    isSubmitting: boolean;
}

export const getFieldErrorNames = (formikErrors: any) => {
    const transformObjectToDotNotation = (obj: any, prefix = '', result = []) => {
        Object.keys(obj).forEach((key) => {
            const value = obj[key]
            if (!value) return

            const nextKey = prefix ? `${prefix}.${key}` : key
            if (typeof value === 'object') {
                transformObjectToDotNotation(value, nextKey, result)
            } else {
                // @ts-ignore
                result.push(nextKey)
            }
        })

        return result
    }

    return transformObjectToDotNotation(formikErrors)
}

export const ScrollToFieldError = ({scrollBehavior = { behavior: 'smooth', block: 'center' },}) => {
    const { submitCount, isValid, errors } = useFormikContext();

    useEffect(() => {
        if (isValid) return;

        const fieldErrorNames = getFieldErrorNames(errors)
        if (fieldErrorNames.length <= 0) return

        const element = document.querySelector(
            `input[name='${fieldErrorNames[0]}']`
        )
        if (!element) return;

        // Scroll to first known error into view
        // @ts-ignore
        element.scrollIntoView(scrollBehavior)
    }, [submitCount, errors, isValid, scrollBehavior])

    return null
}

const FormContainer = ({
    values,
    game,
    locations,
    variables,
    serverTypes,
    gamePackage,
    selectedLocation,
    setSelectedLocation,
    selectedServerType,
    setSelectedServerType,
    paramsError,
    paramsLoading,
    setFieldValue,
    isSubmitting,
}: FormContainerProps) => {
    const currencyValue = useAppSelector((state) => state.currency.value);

    const { data: baseCpuPrice, error: baseCpuPriceError } = useQuery({queryKey: ['global_cpu_price', values.billingCycle, currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.cpu, values.billingCycle, currencyValue?.id || 1)});
    const { data: baseRamPrice, error: baseRamPriceError } = useQuery({queryKey: ['global_ram_price', values.billingCycle, currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.ram, values.billingCycle, currencyValue?.id || 1)});
    const { data: baseDiskPrice, error: baseDiskPriceError } = useQuery({queryKey: ['global_disk_price', values.billingCycle, currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.disk, values.billingCycle, currencyValue?.id || 1)});
    const { data: baseSlotsPrice, error: baseSlotsPriceError } = useQuery({queryKey: ['global_slots_price', values.billingCycle, currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.slots, values.billingCycle, currencyValue?.id || 1)});

    const hasError = baseCpuPriceError || baseRamPriceError || baseDiskPriceError || baseSlotsPriceError;

    if (hasError) {
        return (
            <div className="error-message">
                <p>Failed to load pricing information. Please try again later.</p>
            </div>
        );
    }

    return (
        <>
            <div className="xl:col-span-5 text-white pt-52 px-10">
                <div className="ml-8 mb-9">
                    <h3 className="italic font-[600] uppercase text-[20px] mb-3">
                        order your server
                    </h3>
                    <h1 className="uppercase text-[54px] font-[800] leading-[55px]">
                        {game.name}
                        <p>server hosting</p>
                    </h1>
                </div>
                {/*@ts-ignore*/}
                <LocationFields locations={locations} locationValue={values.location} setFieldValue={setFieldValue} />
                <Capability game={game} serverTypes={serverTypes} values={values} setFieldValue={setFieldValue} />
                <Variables variables={variables} values={values} setFieldValue={setFieldValue} />
                {/*@ts-ignore*/}
                <Billing
                    game={game}
                    values={values}
                    setFieldValue={setFieldValue}
                />
            </div>
            <div className="lg:w-1/2 sm:w-2/3 xl:w-full m-auto px-5 lg:px-0 xl:m-0 xl:col-span-2">
                <RightSide
                    game={game}
                    values={values}
                    locations={locations}
                    serverTypes={serverTypes}
                    gamePackage={gamePackage}
                    isSubmitting={isSubmitting}
                    selectedLocation={selectedLocation}
                    selectedServerType={selectedServerType}
                    setSelectedLocation={setSelectedLocation}
                    setSelectedServerType={setSelectedServerType}
                    paramsError={paramsError}
                    paramsLoading={paramsLoading}
                    baseCpuPrice={baseCpuPrice}
                    baseRamPrice={baseRamPrice}
                    baseDiskPrice={baseDiskPrice}
                    baseSlotsPrice={baseSlotsPrice}
                />
            </div>
        </>
    )
}

const OrderForm = ({game, locations, variables, serverTypes, gamePackage, schema, initialValues}: OrderFormProps) => {
    const { isLoading: paramsLoading, data: params, error: paramsError } = useQuery({queryKey: ['global_params'], queryFn: getParams});
    const [selectedLocation, setSelectedLocation] = useState<Location>();
    const [selectedServerType, setSelectedServerType] = useState<ServerType>();
    const currencyValue = useAppSelector((state) => state.currency.value);

    const onSubmit = (values: any, {setSubmitting}: FormikHelpers<any>) => {
        if (!params) {
            setSubmitting(false);
            return;
        }

        const url = new URL('https://clients.fragnet.net/cart.php?a=add&skipconfig=1');
        let search_params = url.searchParams;

        //game
        search_params.set('pid', String(game.config_option));

        // Locations
        search_params.set(`configoption[${params.location_config_option}]`, String(selectedLocation?.config_option));

        //Server name
        search_params.set('cf_servername', values.name);

        //Cpu, ram, disk | slots
        if (game.fragify) {
            console.log(values.disk);
            search_params.set(`configoption[${params.cpu_config_option}]`, values.cpu);
            search_params.set(`configoption[${params.ram_config_option}]`, values.ram);
            search_params.set(`configoption[${params.disk_config_option}]`, values.disk);
        } else {
            search_params.set(`configoption[${game.slots_config_option}]`, values.slots);
        }

        //Variables
        for (let variable of variables) {
            search_params.set(`${variable.identifier}`, values[variable.identifier]);
        }

        //Server type
        if (selectedServerType) {
            search_params.set(`configoption[${params.server_type_config_option}]`, String(selectedServerType.config_option));
        }

        //Billing cycle
        search_params.set('biilingcycle', values.billingCycle);

        //currency
        if (currencyValue) {
            search_params.set('currency', String(currencyValue.id));
        }

        url.search = search_params.toString();

        let new_url = url.toString();
        console.log(new_url);
        // window.location.href = new_url;

        setSubmitting(false);
    }

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={object().shape(schema)}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    <ScrollToFieldError />
                    <div className="xl:grid xl:grid-cols-7 xl:gap-4 container m-auto">
                        <FormContainer
                            values={values}
                            game={game}
                            locations={locations}
                            variables={variables}
                            serverTypes={serverTypes}
                            gamePackage={gamePackage}
                            selectedLocation={selectedLocation}
                            selectedServerType={selectedServerType}
                            setSelectedLocation={setSelectedLocation}
                            setSelectedServerType={setSelectedServerType}
                            paramsError={paramsError}
                            paramsLoading={paramsLoading}
                            setFieldValue={setFieldValue}
                            isSubmitting={isSubmitting}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const Order: React.FC = () => {
    const {slug, package_id} = useParams();
    const [initialValues, setInitialValues] = React.useState<object>({
        name: '',
        billingCycle: 'monthly',
    });
    const [schema, setSchema] = React.useState<ObjectShape>({
        location: number().required('You should select a location.'),
        name: string().min(3, 'Server name should have at least 3 characters')
            .max(60, 'Server name should not have more than 60 characters')
            .matches(/^[^!@#$%^&*()[\]}{+=/?><.,'";:\\|]*?$/, 'Server name should contain only letters, numbers and underscores')
            .required('Server name is required.'),
        billingCycle: string().required('Billing cycle is required'),
    });
    const [canShowForm, setCanShowForm] = React.useState<boolean>(false);
    const { isLoading: locationsLoading, data: locations, error: locationsError } = useQuery({queryKey: ['locations'], queryFn: getLocations})
    // @ts-ignore
    const { isLoading: gameLoading, data: game, error: gameError } = useQuery({queryKey: ['game', slug], queryFn: () => getGame(slug)});
    // @ts-ignore
    const { isLoading: packageLoading, data: gamePackage } = useQuery({queryKey: ['package', package_id], queryFn: () => getPackage(package_id)});
    // @ts-ignore
    const { isLoading: typesLoading, data: serverTypes, error: typesError } = useQuery({queryKey: ['game/server_types', slug], queryFn: () => getServerType(slug)});
    // @ts-ignore
    const { isLoading: variableLoading, data: variables, error: variableError } = useQuery({queryKey: ['game/variables', slug], queryFn: () => getGameVariables(slug)});

    useEffect(() => {
        if (!locations || locations.length <= 0) return;
        setInitialValues((prevState) => ({
            ...prevState,
            location: locations[0].id,
        }))
    }, [locations]);

    useEffect(() => {
        if (!game || (package_id && !gamePackage)) return;

        if (game.fragify) {
            setSchema((prevState) => {
                return {
                    ...prevState,
                    cpu: number().required().min(1, 'CPU must be greater than 1'),
                    ram: number().required().min(1, 'RAM must be greater than 1'),
                    disk: number().required().min(1, 'Disk must be greater than 1'),
                }
            });
            setInitialValues((prevState) => ({
                ...prevState,
                cpu: gamePackage?.cpu || 2,
                ram: gamePackage?.ram || 8,
                disk: gamePackage?.disk || 10,
            }));
        } else {
            setSchema((prevState) => {
                return {
                  ...prevState,
                    slots: number().required('You should select a number of slots.').min(1, 'You should select a number of slots.'),
                }
            });

            let slotValue = gamePackage?.slots || 1;
            if (!gamePackage) {
                if (game.slots_values) {
                    slotValue = Number(game.slots_values.split(',')[0]);
                } else if (game.slots_min) {
                    slotValue = Number(game.slots_min);
                }
            }
            setInitialValues((prevState) => ({
                ...prevState,
                slots: slotValue,
            }));
        }

    }, [game, gamePackage, package_id]);

    useEffect(() => {
        if (!serverTypes || serverTypes.length <= 0) return;

        setSchema((prevState) => {
            return {
                ...prevState,
                serverType: number().required('You should select a server type.'),
            }
        });
        setInitialValues((prevState) => ({
            ...prevState,
            serverType: serverTypes[0].id,
        }));
    }, [serverTypes]);

    useEffect(() => {
        if (!variables || variables.length <= 0) return;

        for (const variable of variables) {
            setSchema((prevState) => {
                return {
                  ...prevState,
                    [variable.identifier]: Boolean(variable.required) ? string().required('You should enter a value for'+ variable.name) : string().nullable(),
                }
            });
            setInitialValues((prevState) => ({
                ...prevState,
                [variable.identifier]: variable.default_value,
            }));
        }
    }, [variables]);

    useEffect(() => {
        if (!locations || !game || (package_id && !gamePackage)) return;
        else if (game.has_server_types && !serverTypes) return;
        else if (game.has_variables && !variables) return;

        if (Object.keys(initialValues).length >= (variables?.length || 0 + 4)) setCanShowForm(true);
    }, [initialValues, game, gamePackage, locations, package_id, serverTypes, variables]);

    return (
        <>
            <BodyWrapper
                className={classNames("w-full")}
                background={game?.background}
            >
                {(game && variables && serverTypes && locations && canShowForm) ? (
                    <OrderForm game={game} locations={locations} variables={variables} serverTypes={serverTypes} gamePackage={gamePackage} schema={schema} initialValues={initialValues} />
                ) : (gameLoading || locationsLoading || typesLoading || variableLoading || packageLoading) ? (
                    <div className="flex flex-col items-center justify-center py-[200px]">
                        <FontAwesomeIcon icon={faSpinner} className="w-[100px] h-[100px] mb-10" spin />
                        <h1 className="text-[24px] font-[600] text-white">
                            Loading...
                        </h1>
                    </div>
                ) : (
                    (gameError || locationsError || typesError || variableError) && (
                        <div className="p-4 text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800 my-[200px]" role="alert">
                            <div className="flex items-center">
                                <FontAwesomeIcon icon={faInfoCircle} className={'me-2'} />
                                <h3 className="text-lg font-medium">Error</h3>
                            </div>
                            <div className="mt-2 mb-4 text-sm">
                                An error occurred while loading server configuration. Please try again later.
                            </div>
                        </div>
                    )
                )}
            </BodyWrapper>
            <Footer/>
        </>
    );
};

export default Order;
