import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {ErrorMessage} from "formik";
import {useQuery} from "@tanstack/react-query";
import {getPrice} from "../../utils";
import {useAppSelector} from "../../hooks";
import {baseConfigs} from "../../configs";
import {Game} from "../../types";
import { I18n } from "react-redux-i18n";

const BillingWrapper = styled.div` 
    & .item-selected {
        background-image: linear-gradient(180deg, #E90E0E21 0%, #E90E0E00 100%);
        border: 2px solid #e90e0e;
  }
`;

type BillingProps = {
    game: Game;
    values: any;
    setFieldValue: any;
};

const Billing = ({game, values, setFieldValue}: BillingProps) => {
    const currencyValue = useAppSelector((state) => state.currency.value);

    const { data: monthCpuPrice, error: monthCpuPriceError } = useQuery({queryKey: ['global_cpu_price', 'monthly', currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.cpu, 'monthly', currencyValue?.id || 1)});
    const { data: monthRamPrice, error: monthRamPriceError } = useQuery({queryKey: ['global_ram_price', 'monthly', currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.ram, 'monthly', currencyValue?.id || 1)});
    const { data: monthDiskPrice, error: monthDiskPriceError } = useQuery({queryKey: ['global_disk_price', 'monthly', currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.disk, 'monthly', currencyValue?.id || 1)});
    const { data: monthSlotsPrice, error: monthSlotsPriceError } = useQuery({queryKey: ['global_slots_price', 'monthly', currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.slots, 'monthly', currencyValue?.id || 1)});

    const { data: quarterCpuPrice, error: quarterCpuPriceError } = useQuery({queryKey: ['global_cpu_price', 'quarterly', currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.cpu, 'quarterly', currencyValue?.id || 1)});
    const { data: quarterRamPrice, error: quarterRamPriceError } = useQuery({queryKey: ['global_ram_price', 'quarterly', currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.ram, 'quarterly', currencyValue?.id || 1)});
    const { data: quarterDiskPrice, error: quarterDiskPriceError } = useQuery({queryKey: ['global_disk_price', 'quarterly', currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.disk, 'quarterly', currencyValue?.id || 1)});
    const { data: quarterSlotsPrice, error: quarterSlotsPriceError } = useQuery({queryKey: ['global_slots_price', 'quarterly', currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.slots, 'quarterly', currencyValue?.id || 1)});

    const { data: semesterCpuPrice, error: semesterCpuPriceError } = useQuery({queryKey: ['global_cpu_price', 'semiannually', currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.cpu, 'semiannually', currencyValue?.id || 1)});
    const { data: semesterRamPrice, error: semesterRamPriceError } = useQuery({queryKey: ['global_ram_price', 'semiannually', currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.ram, 'semiannually', currencyValue?.id || 1)});
    const { data: semesterDiskPrice, error: semesterDiskPriceError } = useQuery({queryKey: ['global_disk_price', 'semiannually', currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.disk, 'semiannually', currencyValue?.id || 1)});
    const { data: semesterSlotsPrice, error: semesterSlotsPriceError } = useQuery({queryKey: ['global_slots_price', 'semiannually', currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.slots, 'semiannually', currencyValue?.id || 1)});

    const { data: yearCpuPrice, error: yearCpuPriceError } = useQuery({queryKey: ['global_cpu_price', 'annually', currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.cpu, 'annually', currencyValue?.id || 1)});
    const { data: yearRamPrice, error: yearRamPriceError } = useQuery({queryKey: ['global_ram_price', 'annually', currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.ram, 'annually', currencyValue?.id || 1)});
    const { data: yearDiskPrice, error: yearDiskPriceError } = useQuery({queryKey: ['global_disk_price', 'annually', currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.disk, 'annually', currencyValue?.id || 1)});
    const { data: yearSlotsPrice, error: yearSlotsPriceError } = useQuery({queryKey: ['global_slots_price', 'annually', currencyValue?.id || 1], queryFn: () => getPrice(baseConfigs.slots, 'annually', currencyValue?.id || 1)});

    const [monthPrice, setMonthPrice] = useState<number>();
    const [quarterPrice, setQuarterPrice] = useState<number>();
    const [semesterPrice, setSemesterPrice] = useState<number>();
    const [yearPrice, setYearPrice] = useState<number>();

    useEffect(() => {
        if (game.fragify) {
            if (!values.cpu || !values.ram || !values.disk) return;
            setMonthPrice(
                (Number(monthCpuPrice) * values.cpu) +
                (Number(monthRamPrice) * values.ram) +
                (Number(monthDiskPrice) * (values.disk/10))
            );
        } else {
            if (!values.slots) return;
            setMonthPrice(
                (Number(monthSlotsPrice) * values.slots)
            );
        }
    }, [values.cpu, values.ram, values.disk, values.slots, monthCpuPrice, monthRamPrice, monthDiskPrice, monthSlotsPrice, game.fragify]);

    useEffect(() => {
        if (game.fragify) {
            if (!values.cpu || !values.ram || !values.disk) return;
            setQuarterPrice(
                (Number(quarterCpuPrice) * values.cpu) +
                (Number(quarterRamPrice) * values.ram) +
                (Number(quarterDiskPrice) * (values.disk/10))
            );
        } else {
            if (!values.slots) return;
            setQuarterPrice(
                (Number(quarterSlotsPrice) * values.slots)
            );
        }
    }, [values.cpu, values.ram, values.disk, values.slots, quarterCpuPrice, quarterRamPrice, quarterDiskPrice, quarterSlotsPrice, game.fragify]);

    useEffect(() => {
        if (game.fragify) {
            if (!values.cpu || !values.ram || !values.disk) return;
            setSemesterPrice(
                (Number(semesterCpuPrice) * values.cpu) +
                (Number(semesterRamPrice) * values.ram) +
                (Number(semesterDiskPrice) * (values.disk/10))
            );
        } else {
            if (!values.slots) return;
            setSemesterPrice(
                (Number(semesterSlotsPrice) * values.slots)
            );
        }
    }, [values.cpu, values.ram, values.disk, values.slots, semesterCpuPrice, semesterRamPrice, semesterDiskPrice, semesterSlotsPrice, game.fragify]);

    useEffect(() => {
        if (game.fragify) {
            if (!values.cpu || !values.ram || !values.disk) return;
            setYearPrice(
                (Number(yearCpuPrice) * values.cpu) +
                (Number(yearRamPrice) * values.ram) +
                (Number(yearDiskPrice) * (values.disk/10))
            );
        } else {
            if (!values.slots) return;
            setYearPrice(
                (Number(yearSlotsPrice) * values.slots)
            );
        }
    }, [values.cpu, values.ram, values.disk, values.slots, yearCpuPrice, yearRamPrice, yearDiskPrice, yearSlotsPrice, game.fragify]);

    const hasError = monthCpuPriceError || monthRamPriceError || monthDiskPriceError || monthSlotsPriceError ||
        quarterCpuPriceError || quarterRamPriceError || quarterDiskPriceError || quarterSlotsPriceError ||
        semesterCpuPriceError || semesterRamPriceError || semesterDiskPriceError || semesterSlotsPriceError ||
        yearCpuPriceError || yearRamPriceError || yearDiskPriceError || yearSlotsPriceError;

    if (hasError) {
        return (
            <div className="error-message">
                Failed to retrieve pricing data.
            </div>
        );
    }

    return (
        <div>
            <div className="mb-10">
                <h1 className="inline-flex uppercase font-proxima-bold text-2xl text-white items-center">
                    <div
                        className="h-11 text-center skew-x-[-15deg] leading-[44px] w-12 text-white rounded-[6px] bg-[#e90e0e] bg-[linear-gradient(134deg,#e90e0e_0%,#e90e0e_100%)] shadow-[0_2px_4px_0_rgba(0,0,0,0.69),inset_0_-2px_2px_0_rgba(55,0,0,0.20)] uppercase text-2xl font-proxima-bold mr-6">
                        <div className="skew-x-[15deg]">
                            <span>{game.has_variables ? '4' : '3'}</span>
                        </div>
                    </div>
                    {I18n.t('billingCycle')}
                </h1>
            </div>
            <BillingWrapper className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mb-12 gap-4">
                <div
                    className={`${values.billingCycle==='monthly' && 'item-selected'} col px-3 cursor-pointer bg-[linear-gradient(180deg,#162032_0%,#161E2B_24%,#161E2B_100%)] border border-[#212A39] rounded-[6px] p-[46px_4px_60px_4px] text-center relative`}
                    onClick={() => setFieldValue('billingCycle', 'monthly')}
                >
                    <h1 className="text-[18px] font-[600]">{I18n.t("monthly")}</h1>
                    <div
                        className="inline-block mb-3 rounded-[3px] mt-3 text-white bg-[#293446] skew-x-[-15deg] text-[12px] font-[600] px-[12px] shadow-[0_1px_2px_0_rgba(0,0,0,0.79),inset_0_-1px_1px_0_rgba(55,0,0,0.25)]">
                        <div className="py-[3px] skew-x-[15deg]"><span className="uppercase">{I18n.t('noDiscount')}</span>
                        </div>
                    </div>
                    {monthPrice && (
                        <>
                            <h3 className="text-[24px] font-[700]">{(currencyValue?.name || '')+monthPrice.toFixed(2)}</h3>
                        </>
                    )}
                    <span className="absolute bottom-[12px] left-0 text-center text-[12px] text-[#A6B1C5] opacity-[.66] w-full">{I18n.t('cancelAnytime')}</span>
                </div>
                <div
                    className={`${values.billingCycle==='quarterly' && 'item-selected'} col px-3 cursor-pointer bg-[linear-gradient(180deg,#162032_0%,#161E2B_24%,#161E2B_100%)] border border-[#212A39] rounded-[6px] p-[46px_4px_60px_4px] text-center relative`}
                    onClick={() => setFieldValue('billingCycle', 'quarterly')}
                >
                    <h1 className="text-[18px] font-[600]">Quarterly</h1>
                    <div
                        className="mb-3 rounded-[3px] mt-3 text-white bg-[#293446] skew-x-[-15deg] text-[12px] font-[600] inline-block px-[12px] shadow-[0_1px_2px_0_rgba(0,0,0,0.79),inset_0_-1px_1px_0_rgba(55,0,0,0.25)]">
                        <div className="py-[3px] skew-x-[15deg]"><span className="uppercase">10% discount</span>
                        </div>
                    </div>
                    {quarterPrice && (
                        <>
                            <h3 className="text-[24px] font-[700]">{(currencyValue?.name || '')+quarterPrice.toFixed(2)}</h3>
                        </>
                    )}
                    <span className="absolute bottom-[12px] left-0 text-center text-[12px] text-[#A6B1C5] opacity-[.66] w-full">{I18n.t('cancelAnytime')}</span>
                </div>
                <div
                    className={`${values.billingCycle==='semiannually' && 'item-selected'} col px-3 cursor-pointer bg-[linear-gradient(180deg,#162032_0%,#161E2B_24%,#161E2B_100%)] border border-[#212A39] rounded-[6px] p-[46px_4px_60px_4px] text-center relative`}
                    onClick={() => setFieldValue('billingCycle', 'semiannually')}
                >
                    <h1 className="text-[18px] font-[600]">Semi-annually</h1>
                    <div
                        className="mb-3 rounded-[3px] mt-3 text-white bg-[#293446] skew-x-[-15deg] text-[12px] font-[600] inline-block px-[12px] shadow-[0_1px_2px_0_rgba(0,0,0,0.79),inset_0_-1px_1px_0_rgba(55,0,0,0.25)]">
                        <div className="py-[3px] skew-x-[15deg]"><span className="uppercase">15% discount</span>
                        </div>
                    </div>
                    {semesterPrice && (
                        <>
                            <h3 className="text-[24px] font-[700]">{(currencyValue?.name || '')+semesterPrice.toFixed(2)}</h3>
                        </>
                    )}
                    <span className="absolute bottom-[12px] left-0 text-center text-[12px] text-[#A6B1C5] opacity-[.66] w-full">{I18n.t('cancelAnytime')}</span>
                </div>
                <div
                    className={`${values.billingCycle==='annually' && 'item-selected'} col px-3 cursor-pointer bg-[linear-gradient(180deg,#162032_0%,#161E2B_24%,#161E2B_100%)] border border-[#212A39] rounded-[6px] p-[46px_4px_60px_4px] text-center relative`}
                    onClick={() => setFieldValue('billingCycle', 'annually')}
                >
                    <h1 className="text-[18px] font-[600]">Annually</h1>
                    <div
                        className="mb-3 rounded-[3px] mt-3 text-white bg-[#293446] skew-x-[-15deg] text-[12px] font-[600] inline-block px-[12px] shadow-[0_1px_2px_0_rgba(0,0,0,0.79),inset_0_-1px_1px_0_rgba(55,0,0,0.25)]">
                        <div className="py-[3px] skew-x-[15deg]"><span className="uppercase">20% discount</span>
                        </div>
                    </div>
                    {yearPrice && (
                        <>
                            <h3 className="text-[24px] font-[700]">{(currencyValue?.name || '') + yearPrice.toFixed(2)}</h3>
                            {/*<span className="text-[16px] font-[600] ">/year</span> */}
                        </>
                    )}

                    <span className="absolute bottom-[12px] left-0 text-center text-[12px] text-[#A6B1C5] opacity-[.66] w-full">{I18n.t('cancelAnytime')}</span>
                </div>
            </BillingWrapper>
            <div className={'text-sm text-red-500'}>
                <ErrorMessage name={'billingCycle'} />
            </div>
        </div>
    );
};

export default Billing;
