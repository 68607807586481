import React, {useEffect} from "react";

import {Game} from "../types";
import {getGames} from "../utils";
import News from "../components/News";
import Reviews from "../components/Reviews";
import Carousel from "../components/Carousel";
import ReviewCarousel from "../components/ReviewCarousel";
import Features from "../components/Features";
import PromoContainer from "../components/PromoContainer";
import Locations from "../components/Location";
import Support from "../components/Service";
import Footer from "../components/Footer";
import {useQuery} from "@tanstack/react-query";

const HomePage: React.FC = () => {
    const { isLoading: gamesLoading, data: games, error: gamesError } = useQuery({queryKey: ['games'], queryFn: getGames});

    useEffect(() => {
        document.title = "Premium Server Hosting | Built for Gamers";
    }, []);

    useEffect(() => {
        if (!games) return;
        console.log(games);
    }, [games]);

    if (gamesLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <p>Loading Games...</p>
            </div>
        );
    }

    if (gamesError) {
        return <div>Error loading games.</div>;
    }

    return (
        <div>
            {games && (
                <Carousel
                    games={games
                        .sort((a: Game, b: Game) => Number(b.popular) - Number(a.popular))
                        .slice(0, 8)
                        .reverse()}
                />
            )}
            <div className="w-full flex justify-center pt-[88px]">
                <div className="container max-w-[1280px] flex flex-wrap">
                    <News/>
                    <Reviews/>
                </div>
            </div>
            <div className="mt-32">
                <ReviewCarousel/>
            </div>
            <Features/>
            <PromoContainer/>
            <Locations/>
            <Support/>
            <Footer/>
        </div>
    );
};

export default HomePage;
