import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {currencies} from '../configs';

export interface Currency {
    id: number;
    code: 'eur'|'gbp'|'sek'|'usd';
    name: string;
}

// Define a type for the slice state
interface CurrencyState {
    value: Currency|undefined,
}

const initialCurrency: Currency|undefined = currencies.find(c => c.id === 1);

// Define the initial state using that type
const initialState: CurrencyState = {
    value: initialCurrency,
}

export const currencySlice = createSlice({
    name: 'currency',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setCurrency: (state, action: PayloadAction<Currency>) => {
            state.value = action.payload;
        },
    },
});

export const { setCurrency } = currencySlice.actions;

export default currencySlice.reducer;