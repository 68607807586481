import {faArrowRight, faInfoCircle, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {I18n} from "react-redux-i18n";
import {Game, Location, Package, ServerType} from "../../types";
// @ts-ignore
import {kebabCase} from "lodash";
import {billingPeriods} from "../../configs";
import {useAppSelector} from "../../hooks";

type RightSideProps = {
    game: Game;
    gamePackage: Package|undefined;
    locations: Location[];
    serverTypes: ServerType[];
    paramsLoading: boolean;
    paramsError: Error|null;
    values: any;
    isSubmitting: boolean;
    selectedLocation: Location|undefined;
    selectedServerType: ServerType|undefined;
    setSelectedLocation: Dispatch<SetStateAction<Location|undefined>>;
    setSelectedServerType: Dispatch<SetStateAction<ServerType|undefined>>;
    baseCpuPrice: string|undefined;
    baseRamPrice: string|undefined;
    baseDiskPrice: string|undefined;
    baseSlotsPrice: string|undefined;
}

const RightSide = ({
    game,
    gamePackage,
    locations,
    serverTypes,
    values,
    isSubmitting,
    paramsLoading,
    paramsError,
    selectedLocation,
    selectedServerType,
    setSelectedLocation,
    setSelectedServerType,
    baseCpuPrice,
    baseRamPrice,
    baseDiskPrice,
    baseSlotsPrice,
}: RightSideProps) => {
    const currencyValue = useAppSelector((state) => state.currency.value);
    const [cpuPrice, setCpuPrice] = useState<number>();
    const [ramPrice, setRamPrice] = useState<number>();
    const [diskPrice, setDiskPrice] = useState<number>();
    const [slotsPrice, setSlotsPrice] = useState<number>();

    useEffect(() => {
        const currentLocation = locations.find((location) => location.id === values.location);
        setSelectedLocation(currentLocation);
    }, [values.location, locations, setSelectedLocation]);

    useEffect(() => {
        const server_type = serverTypes.find((serverType) => serverType.id === values.serverType);
        setSelectedServerType(server_type);
    }, [values.serverType, serverTypes, setSelectedServerType]);

    useEffect(() => {
        setCpuPrice(undefined);
        if (!baseCpuPrice) return;

        setCpuPrice(Number(baseCpuPrice) * values.cpu);
    }, [values.cpu, baseCpuPrice]);

    useEffect(() => {
        setRamPrice(undefined);
        if (!baseRamPrice) return;

        setRamPrice(Number(baseRamPrice) * values.ram);
    }, [values.ram, baseRamPrice]);

    useEffect(() => {
        setDiskPrice(undefined);
        if (!baseDiskPrice) return;

        setDiskPrice(Number(baseDiskPrice) * (values.disk/10));
    }, [values.disk, baseDiskPrice]);

    useEffect(() => {
        setSlotsPrice(undefined);
        if (!baseSlotsPrice) return;

        setSlotsPrice(Number(baseSlotsPrice) * values.slots);
    }, [values.slots, baseSlotsPrice]);

    return (
        <div className="sticky top-16 bg-[rgba(14,22,35,0.88)] mt-24 mb-12 px-5 py-8 text-center rounded-[9px]">
            {gamePackage ? (
                <>
                    <h1 className="text-white text-2xl uppercase font-proxima-bold text-center">
                        {gamePackage.name}
                    </h1>
                    <h3 className="text-white text-sm uppercase text-center mb-3">
                        {I18n.t('recommended_for')} {gamePackage.recommended_players} {I18n.t('players')}
                    </h3>
                </>
            ) : (
                <h1 className="text-white text-2xl uppercase font-proxima-bold text-center">
                    {I18n.t('summary')}
                </h1>
            )
            }
            <div className={`${game.fragify && 'grid grid-cols-3'} mt-2`}>
                {game.fragify ? (
                    <>
                        <div className="col flex flex-col items-center justify-center py-2 text-center border-r-[1px] border-[rgba(255,255,255,0.12)] px-6">
                            <strong className="text-white">
                                CPU
                            </strong>
                            <span>{values.cpu} <small>Thread{values.cpu > 1 && 's'}</small></span>
                            {cpuPrice && (
                                <small>{(currencyValue?.name || '')+cpuPrice.toFixed(2)}</small>
                            )}
                        </div>
                        <div className="col flex flex-col items-center justify-center py-2 text-center border-r-[1px] border-[rgba(255,255,255,0.12)] px-6">
                            <strong className="text-white ">
                                RAM
                            </strong>
                            <span>{values.ram}GB</span>
                            {ramPrice && (
                                <small>{(currencyValue?.name || '')+ramPrice.toFixed(2)}</small>
                            )}
                        </div>
                        <div className="col flex flex-col items-center justify-center py-2 text-center px-6">
                            <strong className="text-white">
                                Disk
                            </strong>
                            <span>{values.disk}GB</span>
                            {diskPrice && (
                                <small>{(currencyValue?.name || '')+diskPrice.toFixed(2)}</small>
                            )}
                        </div>
                    </>
                ): (
                    <div className="col py-2 text-center px-6">
                        <strong className="flex items-center text-white justify-center">
                            {I18n.t('slots')}
                        </strong>
                        <span>{values.slots} {I18n.t('players')}</span>
                    </div>
                )}
            </div>
            <ul className="pt-4 mx-2 text-start mb-5">
                <li className="py-2 px-5 bg-[#161E2B] mb-1 rounded-t-lg">
                    <h5>{I18n.t('serverName')}:</h5>
                    <strong className="text-white">{values.name}</strong>
                </li>
                <li className="py-2 px-5 bg-[#161E2B] mb-1">
                    <h5>{I18n.t('location')}:</h5>
                    {selectedLocation && (
                        <strong className="flex text-white">
                            {selectedLocation.city}, {selectedLocation.country}
                            <img
                                src={`/flags/${kebabCase(selectedLocation.country)}.svg`}
                                alt={selectedLocation.country}
                                className={`flag-${selectedLocation.short_code} ms-2`}
                                width="16"
                                height="16"
                            />
                        </strong>
                    )}
                </li>
                {selectedServerType && (
                    <li className="py-2 px-5 bg-[#161E2B] mb-1">
                        <h5>{I18n.t('serverType')}:</h5>
                        <strong className="text-white">
                            {selectedServerType.text}
                        </strong>
                        <span className={'font-extralight'}> {selectedServerType.description && "-"} {selectedServerType.description}</span>
                    </li>
                )}
                <li className="py-2 px-5 bg-[#161E2B] mb-1 rounded-b-lg">
                    <h5>{I18n.t('billingCycle')}:</h5>
                    <strong className="text-white flex justify-between">{values.billingCycle.charAt(0).toUpperCase() + values.billingCycle.slice(1)}</strong>
                </li>
            </ul>
            {paramsLoading ? (
                <div className="flex flex-col items-center justify-center">
                    <FontAwesomeIcon icon={faSpinner} className="w-[30px] h-[30px]" spin />
                </div>
            ) : paramsError ? (
                <div className="p-2 text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
                    <div className="flex items-center">
                        <FontAwesomeIcon icon={faInfoCircle} className={'me-2'} />
                        <h3 className="text-lg font-medium">Error</h3>
                    </div>
                    <div className="mt-2 mb-4 text-sm">
                        {I18n.t('priceConfigError')}
                    </div>
                </div>
            ) : (
                <div className="text-center w-full text-white">
                    {game.fragify ? (
                        cpuPrice && ramPrice && diskPrice && (
                            <div className="flex justify-center mb-4">
                                <h3 className="text-3xl font-extrabold mt-2 mb-1">{currencyValue?.name} {(cpuPrice + ramPrice + diskPrice).toFixed(2)}</h3>
                                {/*@ts-ignore*/}
                                <span className="text-sm font-proxima pt-5">/{I18n.t(billingPeriods[values.billingCycle])}</span>
                            </div>
                        )
                    ) : (
                        slotsPrice && (
                            <div className="flex justify-center mb-4">
                                <h3 className="text-3xl font-extrabold mt-2 mb-1">{currencyValue?.name} {(slotsPrice).toFixed(2)}</h3>
                                {/*@ts-ignore*/}
                                <small className="font-medium text-lg pt-4">/{I18n.t(billingPeriods[values.billingCycle])}</small>
                            </div>
                        )
                    )}
                    <button
                        disabled={isSubmitting}
                        type={'submit'}
                        className={`rounded-md z-50 text-center items-center inline-flex h-[54px] px-[32px] text-[18px] font-[600] text-white ${isSubmitting ? 'bg-red-300 hover:bg-red-200' : 'bg-[#e90e0e] hover:bg-[#f91d1d]'}`}
                    >
                        {I18n.t("orderNow")}
                        <FontAwesomeIcon icon={faArrowRight} className="ml-3"/>
                    </button>
                </div>
            )}
        </div>
    );
};

export default RightSide;
