import React from "react";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { I18n } from "react-redux-i18n";

const Affiliate = () => {
  return (
    <div className="w-full my-0 mx-auto md:px-0 px-3">
      <div className="w-full flex justify-center md:pt-[88px] pt-[30px] md:mb-20 mb-12">
        <div className="container max-w-[1280px] lg:flex mt-24">
          <div className="lg:w-1/3 md:text-right text-center md:pr-8">
            <h1 className="uppercase text-white md:text-[60px] text-[36px] md:leading-[50px] leading-[20px] font-[900]">
              {I18n.t("affiliateProgram")}
            </h1>
          </div>
          <div className="lg:w-2/3 md:pl-10 pl-3 lg:pr-40 lg:text-start text-center">
            <p className="mb-8">
              {I18n.t("ourAffiliate")}
              {I18n.t("affiliateDescription")}
            </p>
            <p className="mb-8">
              {I18n.t("affiliateDescription1")}
            </p>
            <h5 className="text-white font-bold">{I18n.t("howItWorks")}</h5>
            <p className="mb-8">
              {I18n.t("affiliateDescription2")}
            </p>
            <h5 className="text-white font-bold">{I18n.t("betterThanSponsorship")}</h5>
            <p className="mb-8">
              {I18n.t("affiliateDescription3")}
            </p>
            <h5 className="text-white font-bold">
              {I18n.t("updatingStatistics")}
            </h5>
            <p className="mb-8">
              {I18n.t("affiliateDescription4")}
            </p>
            <h5 className="text-white font-bold">
              {I18n.t("questions")}
            </h5>
            <div className="mt-5">
              <Link
                to="#"
                className="inline-flex rounded-md z-50 text-center md:mx-0 mx-auto items-center h-[48px] px-[24px] text-[16px] font-[800] bg-[#e90e0e] text-white hover:bg-[#f91d1d] text-white"
              >
                {I18n.t("contactUs")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full border-t border-[rgba(255,255,255,.12)]">
        <div className="container max-w-[1280px] mx-auto my-24 lg:flex">
          <div className="lg:w-1/3 md:text-right text-center md:pr-8">
            <h1 className="uppercase text-white text-[36px] leading-[44px] font-[900]">
                {I18n.t("details")}
            </h1>
          </div>
          <div className="lg:w-2/3 md:pl-10 pl-3 lg:pr-40 lg:text-start text-center">
            <h5 className="text-white font-bold">{I18n.t("commisionType")}</h5>
            <p>{I18n.t("salesDeliver")}</p>
            <h5 className="text-white font-bold mt-6">{I18n.t("payoutAmount")}</h5>
            <p className="mb-8">
              €20 - {I18n.t("minimumEarn")}
            </p>
            <h5 className="text-white font-bold">{I18n.t("signupBonus")}</h5>
            <p className="mb-8">
              €5 - {I18n.t("bonus")}
            </p>
            <h5 className="text-white font-bold">{I18n.t("withdrawal")}</h5>
            <p className="mb-8">
              {I18n.t("agreement")}{" "}
              <Link to="https://clients.fragnet.net/submitticket.php?step=2&deptid=2">
                {I18n.t("reachOutToUs")}
              </Link>
            </p>
            <h5 className="text-white font-bold">{I18n.t("readyToEarn")}</h5>
            <div className="mt-5">
              <Link
                to="#"
                className="inline-flex rounded-md z-50 text-center md:mx-0 mx-auto items-center h-[48px] px-[24px] text-[16px] font-[800] bg-[#e90e0e] text-white hover:bg-[#f91d1d] text-white"
              >
                {I18n.t("signUpHere")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Affiliate;
