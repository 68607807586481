import React from "react";
import Footer from "../components/Footer";
import { I18n } from "react-redux-i18n";

const Privacy = () => {
  return (
    <div className="w-full my-0 mx-auto md:px-0 px-3">
      <div className="w-full pt-24 border-t border-[rgba(255,255,255,.12)]">
        <div className="container max-w-[1280px] mx-auto my-24 lg:flex">
          <div className="lg:w-1/3 md:text-right text-center md:pr-8">
            <h1 className="uppercase text-white text-[36px] leading-[44px] font-[900]">
              {I18n.t("privacyPolicy")}
            </h1>
          </div>
          <div className="lg:w-2/3 md:pl-10 pl-3 lg:pr-40 lg:text-start text-center">
            <p className="mb-8">
              This Privacy Policy governs the manner in which Fragnet Networks
              International AB ("We", "Us", "Fragnet Networks", "Fragnet")
              collects, uses, maintains and discloses information collected from
              users (each, a “User”) of the following domains (each a “Site”).
              <br />
              fragnet.net
              <br /> clients.fragnet.net
              <br /> gamepanel.fragnet.net
              <br />
              panel.fragify.net
              <br /> When and how we collect and handle your personal data
            </p>{" "}
            <p className="mb-8">
              WHEN YOU VISIT OUR SITE
              <br /> Cookies <br />
              We use cookies to enhance User experience on our Sites, for
              statistics and marketing purposes. Only strictly necessary cookies
              will be set until we get your explicit permission to use other
              cookies. To see a full list of cookies that we use on this Site,
              please view our cookie declaration.
            </p>
            <p className="mb-8">
              Visitor statistics
              <br />
              Anonymous visitor data is collected so that we can get a better
              picture of how our visitors interact with our web page.
            </p>
            <p className="mb-8">
              WHEN YOU SIGN UP FOR AN ACCOUNT
              <br />
              We collect your e-mail address, contact details and billing
              information to be able to create your account and provide your
              credentials to our game server management systems.
              <br /> We will use your email address to send information about
              our services which we deem important, urgent, critical or in any
              other way of interest for our users and their usage, billing,
              security, privacy or other similar matters related to the service
              or your usage. These emails are sent from within the game server
              management systems and can not be opted out from.
            </p>
            <h1 className="text-white text-[30px] leading-[38px] font-[900] uppercase">
              WHY WE COLLECT YOUR DATA
            </h1>
            <p className="mb-8">
              City Network International AB collects and uses personal data for
              the following purposes:
              <br /> To provide our services as requested by you <br />
              We need your personal data to verify your identity, to supply your
              credentials to our services and to collect your payment for the
              provided services.
            </p>
            <p className="mb-8">
              To respond to your requests, and improve customer service <br />
              If you contact us, request a call-back, quote, customer service
              request or other contact request. Information you provide helps us
              respond to your customer service requests and support needs more
              efficiently.
            </p>
            <p className="mb-8">
              To send marketing emails
              <br /> To send Users information they agreed to receive about
              topics we think will be of interest to them.
            </p>
            <p className="mb-8">
              For advertising purposes <br />
              By visiting this site third parties will place cookies on your
              browser for targeted advertising purposes. Our cookie declaration
              details which cookies we use and what they store.
            </p>
            <p className="mb-8">
              How we protect your information
              <br /> We adopt appropriate data collection, storage and
              processing practices and security measures to protect against
              unauthorized access, alteration, disclosure or destruction of your
              personal information, username, password, transaction information
              and data stored on our Site. Sensitive and private data exchange
              between the Site and its Users happens over a SSL secured
              communication channel and is encrypted and protected with digital
              signatures.
            </p>
            <p className="mb-8">
              Sharing your personal information
              <br />
              We do not sell, trade, or rent Users personal data to others. We
              share generic aggregated demographic information, not linked to
              any personal data, regarding visitors and users with our business
              partners, trusted affiliates and advertisers for the purposes
              outlined above.We use third party service providers to help us
              operate our business and the Site or administer activities on our
              behalf, such as sending out newsletters or surveys. We will share
              your information with these third parties for the limited purposes
              outlined above.
            </p>
            <p className="mb-8">
              Sub processors and countries your data will be sent to
              <br />
              City Network International AB uses certain subprocessors and
              subcontractors to provide you with our services and information
              connected to our services and company. A subprocessor is a third
              party data processor engaged by us that provides our service with
              various functions. We make sure that there are proper agreements
              in place to keep your personal data safe. City Network is a global
              company and your personal data may be transferred across
              international borders. When we send your data to countries with
              different levels of data protection laws we make sure it is
              transferred with an adequate level of protection.
            </p>
            <p className="mb-8">
              Third party websites
              <br /> Users may find advertising or other content on our Site
              that link to the sites and services of our partners, suppliers,
              advertisers, sponsors, licensors and other third parties. We do
              not control the content or links that appear on these sites and
              are not responsible for the practices employed by websites linked
              to or from our Site. In addition, these sites or services,
              including their content and links, may be constantly changing.
              These sites and services may have their own privacy policies and
              customer service policies. Browsing and interaction on any other
              website, including websites which have a link to our Site, is
              subject to that website’s own terms and policies.
            </p>
            <p className="mb-8">
              Changes to this privacy policy <br />
              City Network International AB has the discretion to update this
              privacy policy at any time. When we do, we will revise the updated
              date at the bottom of this page. We encourage Users to frequently
              check this page for any changes to stay informed about how we are
              helping to protect the personal data we collect. You acknowledge
              and agree that it is your responsibility to review this privacy
              policy periodically and become aware of modifications.
            </p>
            <p className="mb-8">
              Your acceptance of these terms
              <br />
              By using this Site, you signify your acceptance of this policy. If
              you do not agree to this policy, please do not use our Site. Your
              continued use of the Site following the posting of changes to this
              policy will be deemed your acceptance of those changes.
            </p>
            <p className="mb-8">
              Contacting us
              <br /> If you have any questions about this Privacy Policy, the
              practices of this site or your dealings with this site, please
              contact us at:
              <br /> Fragnet Networks International AB <br />
              Blekingegatan 1 SE-37157
              <br /> Karlskrona <br />
              SWEDEN
              <br /> dpo@fragnet.net
              <br /> This document was last updated on June 8, 2022
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
