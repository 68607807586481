import React, { useState, useEffect } from 'react';
import ReactSlider from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components";
import {Game, ServerType} from "../../types";
import {ErrorMessage} from "formik";
import { Field } from 'formik';
import {diskConfigs, ramConfigs} from "../../configs";
import serverNames from "./serverNames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsRotate} from "@fortawesome/free-solid-svg-icons";
import { I18n } from "react-redux-i18n";
// @ts-ignore
import {last} from "lodash";

const ServerTypeWrapper = styled.div`
  & .item-selected {
    background-image: linear-gradient(180deg, rgba(233, 14, 14, 0.13) 0%, rgba(233, 14, 14, 0.00) 100%);
    border: 2px solid #e90e0e;

    &::after {
      position: absolute;
      top: -20px;
      left: 50%;
      margin-left: -27px;
      content: " ";
      width: 54px;
      height: 45px;
      background: url('/selected.svg');
    }
  }
`;

const FixedSlotsWrapper = styled.div` 
    & .item-selected {
        background-image: linear-gradient(180deg, rgba(233, 14, 14, 0.13) 0%, rgba(233, 14, 14, 0.00) 100%);
        border: 2px solid #e90e0e;
  }
`;

type ServerNameProps = {
    game: Game;
};

type CapabilityProps = {
    game: Game;
    serverTypes: ServerType[];
    values: any;
    setFieldValue: (fieldName: string, value: any) => void;
};

type ServerTypeConfigProps = {
    serverTypes: ServerType[];
    serverTypeValue: number;
    setFieldValue: (fieldName: string, value: any) => void;
}

type FragifyConfigsProps = {
    values: any;
    setFieldValue: (fieldName: string, value: any) => void;
}

type CPUConfigProps = {
    cpuValue: number;
    setFieldValue: (fieldName: string, value: any) => void;
}

type RAMConfigProps = {
    ramValue: number;
    setFieldValue: (fieldName: string, value: any) => void;
}

type DiskConfigProps = {
    diskValue: number;
    setFieldValue: (fieldName: string, value: any) => void;
}

type SlotsConfigProps = {
    slotValue: number;
    setFieldValue: (fieldName: string, value: any) => void;
    slotsMin: number;
    slotsMax: number;
}

type FixedSlotsConfigProps = {
    values: number[];
    slotValue: number;
    setFieldValue: (fieldName: string, value: any) => void;
}

const ServerName = ({ game, setFieldValue }: ServerNameProps & { setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void }) => {
    const defaultName = `My ${game.name} Server`;
    const [currentName, setCurrentName] = useState(defaultName);

    useEffect(() => {
        setFieldValue("name", defaultName);
    }, [setFieldValue, defaultName]);

    const setRandomName = () => {
        const randomIndex = Math.floor(Math.random() * serverNames.length);
        const newName = serverNames[randomIndex];
        setCurrentName(newName);
        setFieldValue("name", newName);
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentName(e.target.value);
        setFieldValue("name", e.target.value);
    };

    return (
        <div className="mb-8 flex items-center">
            <div className="flex-grow">
                <h1 className="text-[18px] font-[500] mb-3">{I18n.t('nameYourServer')}:</h1>
                <Field
                    name="name"
                    className="h-12 w-full py-3 border-[#2D3747] border text-[18px] rounded bg-[#0E1623]/0 px-2.5 outline-none text-white focus:border-[#E90E0E]"
                    type="text"
                    value={currentName}
                    onChange={handleNameChange}
                />
                <div className="text-sm text-red-500">
                    <ErrorMessage name="name" />
                </div>
            </div>
            <button
                onClick={setRandomName}
                className="ml-4 mt-9 px-4 py-2 bg-red-600 text-white border-0 hover:bg-red-700 rounded focus:outline-none align-middle"
            >
                <FontAwesomeIcon icon={faArrowsRotate} className={'h-5 w-5'} />
            </button>
        </div>
    );
};


const CPUConfig = ({cpuValue, setFieldValue}: CPUConfigProps) => {
    return (
        <div>
            <h1 className="text-[18px] font-[500] mb-3">CPU Threads:</h1>
            <div className="mb-10">
                <ReactSlider
                    styles={{
                        track: {backgroundColor: "#e90e0e", height: "15px"},
                        rail: {backgroundColor: "rgba(14,22,35)", height: "15px"},
                        handle: {
                            borderColor: "#e90e0e",
                            height: 28,
                            width: 28,
                            marginTop: "-6px",
                            backgroundColor: "#292f3a",
                            opacity: 1,
                        }
                    }}
                    min={1}
                    max={4}
                    value={cpuValue}
                    onChange={(value) => setFieldValue('cpu', value)}
                />
                <ul className="mt-3 grid grid-cols-3">
                    <li className={`text-start flex flex-col ${cpuValue===1 ? 'text-red-500' : ''}`}>
                        <span className={'text-gray-500'}>|</span>
                        <span>1</span>
                    </li>
                    <li className={'flex justify-between'}>
                        <div className={`flex flex-col ${cpuValue===2 ? 'text-red-500' : ''}`}>
                            <span className={'text-gray-500'}>|</span>
                            <span className={'relative right-1'}>2</span>
                        </div>
                        <div className={`flex flex-col text-end ${cpuValue===3 ? 'text-red-500' : ''}`}>
                            <span className={'text-gray-500'}>|</span>
                            <span className={'relative left-1'}>3</span>
                        </div>
                    </li>
                    <li className={`text-end flex flex-col ${cpuValue===4 ? 'text-red-500' : ''}`}>
                        <span className={'text-gray-500'}>|</span>
                        <span>4</span>
                    </li>
                </ul>
                <div className={'text-sm text-red-500'}>
                    <ErrorMessage name={'cpu'} />
                </div>
            </div>
        </div>
    );
}

const RAMConfig = ({ramValue, setFieldValue}: RAMConfigProps) => {
    return (
        <div>
            <h1 className="text-[18px] font-[500] mb-3">RAM <span className={'xl:hidden'}>(GB)</span> :</h1>
            <div className="mb-10">
                <ReactSlider
                    styles={{
                        track: {backgroundColor: "#e90e0e", height: "15px"},
                        rail: {backgroundColor: "rgba(14,22,35)", height: "15px"},
                        handle: {
                            borderColor: "#e90e0e",
                            height: 28,
                            width: 28,
                            marginTop: "-6px",
                            backgroundColor: "#292f3a",
                            opacity: 1,
                        }
                    }}
                    min={ramConfigs[0].label}
                    max={last(ramConfigs).label}
                    value={ramValue}
                    onChange={(value) => setFieldValue('ram', value)}
                />
                <ul className="mt-3 grid grid-cols-15">
                    <li  className={'flex justify-between'}>
                        <div className={`flex flex-col ${ramValue===ramConfigs[0].label ? 'text-red-500' : ''}`}>
                            <span className={'text-gray-500'}>|</span>
                            <span className={'relative xl:right-4'}>{ ramConfigs[0].label } <span className={'hidden xl:inline'}>GB</span></span>
                        </div>
                        <div className={`flex flex-col text-end ${ramValue===ramConfigs[1].label ? 'text-red-500' : ''}`}>
                            <span className={'text-gray-500'}>|</span>
                            <span className={'relative left-1 xl:left-4'}>{ ramConfigs[1].label } <span className={'hidden xl:inline'}>GB</span></span>
                        </div>
                    </li>
                    {ramConfigs.map((ram, index) => (
                        ram.label > ramConfigs[1].label && (
                            <li key={index} className={`flex text-end flex-col ${ramValue===ram.label ? 'text-red-500' : ''}`}>
                                <span className={'text-gray-500'}>|</span>
                                <span className={'relative left-1 xl:left-4'}>{ram.label} <span className={'hidden xl:inline'}>GB</span></span>
                            </li>
                        )
                    ))}
                </ul>
                <div className={'text-sm text-red-500'}>
                    <ErrorMessage name={'ram'} />
                </div>
            </div>
        </div>
    );
}

const DiskConfig = ({diskValue, setFieldValue}: DiskConfigProps) => {
    return (
        <div>
            <h1 className="text-[18px] font-[500] mb-3">DISK <span className={'lg:hidden'}>(GB)</span>:</h1>
            <div className="mb-12">
                <ReactSlider
                    styles={{
                        track: {backgroundColor: "#e90e0e", height: "15px"},
                        rail: {backgroundColor: "rgba(14,22,35)", height: "15px"},
                        handle: {
                            borderColor: "#e90e0e",
                            height: 28,
                            width: 28,
                            marginTop: "-6px",
                            backgroundColor: "#292f3a",
                            opacity: 1,
                        }
                    }}
                    min={diskConfigs[0].label}
                    max={last(diskConfigs).label}
                    step={10}
                    value={diskValue}
                    onChange={(value) => setFieldValue('disk', value)}
                />
                <ul className="mt-3 grid grid-cols-11">
                    <li  className={'flex justify-between'}>
                        <div className={`flex flex-col ${diskValue===diskConfigs[0].label ? 'text-red-500' : ''}`}>
                            <span className={'text-gray-500'}>|</span>
                            <span className={'relative right-2 lg:right-4'}>{diskConfigs[0].label} <span className={'hidden lg:inline'}>GB</span></span>
                        </div>
                        <div className={`flex flex-col text-end ${diskValue===diskConfigs[1].label ? 'text-red-500' : ''}`}>
                            <span className={'text-gray-500'}>|</span>
                            <span className={'relative left-2 lg:left-4'}>{diskConfigs[1].label} <span className={'hidden lg:inline'}>GB</span></span>
                        </div>
                    </li>
                    {diskConfigs.map((disk, index) => (
                        disk.label > diskConfigs[1].label && (
                            <li key={index} className={`flex text-end flex-col ${diskValue===disk.label ? 'text-red-500' : ''}`}>
                                <span className={'text-gray-500'}>|</span>
                                <span className={'relative left-2 lg:left-4'}>{disk.label} <span className={'hidden lg:inline'}>GB</span></span>
                            </li>
                        )
                    ))}
                </ul>
                <div className={'text-sm text-red-500'}>
                    <ErrorMessage name={'disk'} />
                </div>
            </div>
        </div>
    );
}

const FragifyConfigs = ({values, setFieldValue}: FragifyConfigsProps) => {
    return (
        <>
            <CPUConfig cpuValue={values.cpu} setFieldValue={setFieldValue} />
            <RAMConfig ramValue={values.ram} setFieldValue={setFieldValue} />
            <DiskConfig diskValue={values.disk} setFieldValue={setFieldValue} />
        </>
    )
}

const SlotSlideConfig = ({slotValue, setFieldValue, slotsMin, slotsMax}: SlotsConfigProps) => {
    return (
        <div>
            <h1 className="text-[18px] font-[500] mb-3">Slots :</h1>
            <div className="mb-10">
                <ReactSlider
                    styles={{
                        track: {backgroundColor: "#e90e0e", height: "15px"},
                        rail: {backgroundColor: "rgba(14,22,35)", height: "15px"},
                        handle: {
                            borderColor: "#e90e0e",
                            height: 28,
                            width: 28,
                            marginTop: "-6px",
                            backgroundColor: "#292f3a",
                            opacity: 1,
                        }
                    }}
                    min={Number(slotsMin)}
                    max={Number(slotsMax)}
                    value={Number(slotValue)}
                    onChange={(value) => setFieldValue('slots', value)}
                />
                <ul className="mt-5 flex justify-center">
                    <li>
                        <span>{slotValue} {I18n.t('playerSlotsSelected')}</span>
                    </li>
                </ul>
                <div className={'text-sm text-red-500'}>
                    <ErrorMessage name={'slots'} />
                </div>
            </div>
        </div>
    )
}

const FixedSlotsConfig = ({ values, slotValue, setFieldValue }: FixedSlotsConfigProps) => {
    return (
        <div>
            <h1 className="text-[18px] font-[500]">Slots :</h1>
            <FixedSlotsWrapper className="grid sm:grid-cols-3 lg:grid-cols-4">
                {values.map((value, index) => (
                    <div className={'col px-3 cursor-pointer mt-8'} key={index} onClick={() => setFieldValue('slots', value)}>
                        <div className={`${value === slotValue ? 'item-selected' : ''} bg-[linear-gradient(180deg,#162032_0%,#161E2B_24%,#161E2B_100%)] border border-[#212A39] rounded-[6px] p-[20px_4px_20px_4px] text-center`}>
                            <h3 className="text-[25px] text-white">{ value }</h3>
                            <span className="w-full text-[15px] text-[#A6B1C5] opacity-[.66]">players</span>
                        </div>
                    </div>
                ))}
            </FixedSlotsWrapper>
            <div className={'text-sm text-red-500'}>
                <ErrorMessage name={'serverType'} />
            </div>
        </div>
    );
}

const ServerTypeConfig = ({ serverTypes, serverTypeValue, setFieldValue }: ServerTypeConfigProps) => {
    return (
        <>
            {serverTypes && serverTypes.length > 0 && (
                <div>
                    <h1 className="text-lg sm:text-xl md:text-[18px] font-[500] mb-3">
                        {I18n.t('chooseYourServerType')}:
                    </h1>
                    <ServerTypeWrapper className="grid sm:grid-cols-3 lg:grid-cols-4">
                        {serverTypes.map((type, index) => (
                            <div className={'col px-3 cursor-pointer mt-8'} key={index} onClick={() => setFieldValue('serverType', type.id)}>
                                <div className={`${serverTypeValue === type.id ? 'item-selected' : ''} bg-[linear-gradient(180deg,#162032_0%,#161E2B_24%,#161E2B_100%)] border border-[#212A39] rounded-[6px] p-[46px_4px_60px_4px] text-center relative`}>
                                    <img
                                        src={type.icon}
                                        alt="modpack"
                                        className="mx-auto"
                                        width="90"
                                    />
                                    <h1 className="mt-3 text-[18px] text-white ">{ type.text }</h1>
                                    <span className="absolute w-full bottom-[12px] left-0 text-[12px] text-[#A6B1C5] opacity-[.66]">
                                {type.description}
                            </span>
                                </div>
                            </div>
                        ))}
                    </ServerTypeWrapper>
                    <div className={'text-sm text-red-500'}>
                        <ErrorMessage name={'serverType'} />
                    </div>
                </div>
            )}
        </>
    );
}

const Capability = ({ game, serverTypes, values, setFieldValue }: CapabilityProps) => {
    return (
        <div className={'mb-12'}>
            <div>
                <h1 className="inline-flex uppercase font-proxima-bold text-2xl text-white items-center">
                    <div
                        className="h-[44px] text-center skew-x-[-15deg] leading-[44px] w-[48px] text-white rounded-[6px] bg-[#e90e0e] bg-[linear-gradient(134deg,#e90e0e_0%,#e90e0e_100%)] shadow-[0_2px_4px_0_rgba(0,0,0,0.69),inset_0_-2px_2px_0_rgba(55,0,0,0.20)] uppercase text-[24px] font-[600] mr-[24px]">
                        <div className="skew-x-[15deg]">
                            <span>2</span>
                        </div>
                    </div>
                    {I18n.t('customizeYourServer')}
                </h1>
            </div>
            <div className="mt-12 mb-12">
                <ServerName game={game} setFieldValue={setFieldValue}/>
                {game.fragify ? (
                    <FragifyConfigs values={values} setFieldValue={setFieldValue} />
                ) : (
                    game.slots_min && game.slots_max ? (
                        <SlotSlideConfig slotValue={values.slots} setFieldValue={setFieldValue} slotsMin={game.slots_min} slotsMax={game.slots_max} />
                    ) : (
                        game.slots_values && (
                            <FixedSlotsConfig
                                values={game.slots_values.split(',').map(value => Number(value))}
                                slotValue={values.slots}
                                setFieldValue={setFieldValue}
                            />
                        )
                    )
                )}
                <ServerTypeConfig serverTypes={serverTypes} serverTypeValue={values.serverType} setFieldValue={setFieldValue} />
            </div>
        </div>
    );
};

export default Capability;
