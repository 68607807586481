import {Game, Location, Package, Parameter, Pricing, ServerType, Variable, Review, DedicatedServer} from "./types";
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const PRICE_URL = process.env.REACT_APP_PRICE_URL;

// export const fetchData = async (endpoint: string) => {
//     const response = await fetch(
//         `https://back-web01.fragnet.net/api/v1${endpoint}`
//     );
//     const data = await response.json();
//     return data.data;
// };
//
// export const fetchLocations = async () => {
//     const response = await fetch(
//         `https://back-web01.fragnet.net/api/v1/locations`
//     );
//     const data = await response.json();
//     return data.data;
// };
//
// export const fetchGameBySlug = async (slug: string | undefined) => {
//     const response = await fetch(
//         `https://back-web01.fragnet.net/api/v1/games/${slug}`
//     );
//     const data = await response.json();
//     return data.data;
// };
//
// export const fetchReviews = async () => {
//     const response = await fetch(`https://back-web01.fragnet.net/api/v1/reviews`);
//     const data = await response.json();
//     return data.data;
// };

export const getGames = (): Promise<Game[]> => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_URL}/games`).then(({ data }) => {
            resolve(data);
        }).catch((err) => {
            reject(err);
        })
    })
}

export const getGame = (slug: string): Promise<Game> => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_URL}/games/${slug}`).then((res) => {
            resolve(res.data);
        }).catch((err) => {
            reject(err);
        })
    })
}

export const getLocations = (): Promise<Location[]> => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_URL}/locations`).then(({ data }) => {
            resolve(data);
        }).catch(error => {
            reject(error);
        });
    });
}

export const getReviews = (): Promise<Review[]> => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_URL}/reviews`).then(({ data }) => {
            resolve(data);
        }).catch((err) => {
            reject(err);
        })
    })
}

export const getGameVariables = (slug: string): Promise<Variable[]> => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_URL}/games/${slug}/variables`).then(({ data }) => {
            resolve(data);
        }).catch(error => {
            reject(error);
        });
    });
}

export const getServerType = (slug: string): Promise<ServerType[]> => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_URL}/games/${slug}/server_types`).then(({ data }) => {
            resolve(data);
        }).catch(error => {
            reject(error);
        });
    });
}

export const getPricing = (slug: string): Promise<Pricing[]> => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_URL}/games/${slug}/pricing`).then(({ data }) => {
            resolve(data);
        }).catch(error => {
            reject(error);
        });
    });
}

export const getPackage = (id: number): Promise<Package> => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_URL}/packages/${id}`).then(({ data }) => {
            resolve(data);
        }).catch(error => {
            reject(error);
        });
    });
}

export const getParams = (): Promise<Parameter> => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_URL}/parameters`).then(({ data }) => {
            resolve(data);
        }).catch(error => {
            reject(error);
        });
    });
}

export const getPrice = (config_id: number, billingCycle: string, currency: number): Promise<string> => {
    return new Promise((resolve, reject) => {
        axios.get(`${PRICE_URL}`, {
            params: {
                config: config_id,
                billingcycle: billingCycle,
                currency: currency,
            }
        }).then(({ data }) => {
            resolve(data);
        }).catch(error => {
            reject(error);
        });
    });
}

export const getDedicatedServers = (
    showingServers: 'all'|'enabled'|'disabled',
    threads: string|number,
    cpuSpeed: number[],
    ramCapacity: number[],
    diskType: string,
    location: number|string,
): Promise<DedicatedServer[]> => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_URL}/dedicated_servers`, {
            params: {
                showing: showingServers,
                location_id: location,
                cpu_threads: threads,
                cpu_speed_min: cpuSpeed[0],
                cpu_speed_max: cpuSpeed[1],
                ram_min: ramCapacity[0],
                ram_max: ramCapacity[1],
                disk_type: diskType,
            }
        }).then(({ data }) => {
            resolve(data);
        }).catch(error => {
            reject(error);
        });
    });
}