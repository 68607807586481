import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Provider} from "react-redux";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import store from "./store";

import Header from "./components/header";
import HomePage from "./pages/home";
import GamePage from "./pages/GamePage";
import GameServers from "./pages/GameServers";
import Order from "./pages/order";
import DiscordBot from "./pages/DiscordBot";
import VoiceServer from "./pages/VoiceServer";
import AboutUs from "./pages/AboutUs";
import Careers from "./pages/Careers";
import Affiliate from "./pages/Affiliate";
import Tos from "./pages/Tos";
import Privacy from "./pages/Privacy";
import DedicatedServers from "./pages/DedicatedServers";

const queryClient = new QueryClient();

const App: React.FC = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <Router>
                    <Header/>
                    <div className={`min-h-screen font-proxima`}>
                        <Routes>
                            <Route path="/" Component={HomePage}/>
                            <Route path="/games/:slug" Component={GamePage}/>
                            {/*<Route path={`/locations`} Component={LocationFields}/>*/}
                            <Route path={"/games/:slug/order/:package_id?"} Component={Order}/>
                            <Route path={"/page/gameservers/"} Component={GameServers}/>
                            <Route path={"/page/discord-bots/"} Component={DiscordBot}/>
                            <Route path={"/page/voiceServers/"} Component={VoiceServer}/>
                            <Route path={"/page/dedicatedServers/"} Component={DedicatedServers}/>
                            <Route path={"/page/about/"} Component={AboutUs}/>
                            <Route path={"/page/careers/"} Component={Careers}/>
                            <Route path={"/page/affiliate/"} Component={Affiliate}/>
                            <Route path={"/page/tos/"} Component={Tos}/>
                            <Route path={"/page/privacy/"} Component={Privacy}/>
                        </Routes>
                    </div>
                </Router>
            </Provider>
        </QueryClientProvider>
    );
};

export default App;
