import React, {Dispatch, SetStateAction, useState} from "react";
import PromoContainer from "../components/PromoContainer";
import Footer from "../components/Footer";
import GameLocation from "../components/GameLocation";
import GameFeatures from "../components/GameFeatures";
import CustomControlPanel from "../components/CustomControlPanel";
import FAQ from "../components/FAQ";
import {useQuery} from "@tanstack/react-query";
import {getDedicatedServers, getLocations} from "../utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faSpinner,
    faMicrochip,
    faMemory,
    faHardDrive,
    faGlobe,
    faMapPin,
} from "@fortawesome/free-solid-svg-icons";
import {DedicatedServer, Disk, RAM} from "../types";
import {useAppSelector} from "../hooks";
// @ts-ignore
import {kebabCase} from "lodash";
import Button from "../components/Button";
import Slider from "rc-slider";
import {Link} from "react-router-dom";
import styled from "styled-components";
import { Collapse } from "react-collapse";
import './DedicatedServer.css';

const LocationWrapper = styled.div`
    display: grid;
    justify-content: center;
    grid-auto-flow: column;
    gap: 20px;
    padding: 20px;

    & .showing-card {
        padding: 10px;
        background-color: #0d1522;
        color: #fff;
        border-radius: 8px;
        border-color: #28364c;
        cursor: pointer;
    }

    & .showing-label {
        text-align: center;
    }

    & .item-selected {
        border: 2px solid #e90e0e;
    }
`;

type DedicatedServerProps = {
    server : DedicatedServer;
}

type RAMValueProps = {
    ram: RAM;
};

type DiskValueProps = {
    disk: Disk;
}

type AdvancedFiltersProps = {
    threads: string|number;
    setThreads: Dispatch<SetStateAction<string|number>>;
    setCpuSpeed: Dispatch<SetStateAction<number[]>>;
    setRamCapacity: Dispatch<SetStateAction<number[]>>;
    diskType: string;
    setDiskType: Dispatch<SetStateAction<string>>;
    location: string|number;
    setLocation: Dispatch<SetStateAction<string|number>>;
    setShowFilters: Dispatch<SetStateAction<boolean>>;
};

type CpuThreadsFilterProps = {
    threads: string|number;
    setThreads: Dispatch<SetStateAction<string|number>>;
};

type CpuSpeedFilterProps = {
    setCpuSpeed: Dispatch<SetStateAction<number[]>>;
};

type RamCapacityFilterProps = {
    setRamCapacity: Dispatch<SetStateAction<number[]>>;
};

type DiskTypeFilterProps = {
    diskType: string;
    setDiskType: Dispatch<SetStateAction<string>>;
};

type LocationFilterProps = {
    location: string|number;
    setLocation: Dispatch<SetStateAction<string|number>>;
};

type TopFilterProps = {
    showingServers: 'all'|'enabled'|'disabled';
    setShowingServers: Dispatch<SetStateAction<'all'|'enabled'|'disabled'>>;
    showFilters: boolean;
    setShowFilters: Dispatch<SetStateAction<boolean>>;
}

const TopFilter = ({showingServers, setShowingServers, showFilters, setShowFilters}: TopFilterProps) => {
    return (
        <>
            <LocationWrapper>
                <div className={`showing-card ${showingServers==='all' && 'item-selected'}`} onClick={() => setShowingServers('all')}>
                    <div className="showing-info">All Servers</div>
                </div>
                <div className={`showing-card ${showingServers==='enabled' && 'item-selected'}`} onClick={() => setShowingServers('enabled')}>
                    <div className="showing-info">In stock</div>
                </div>
                <div className={`showing-card ${showingServers==='disabled' && 'item-selected'}`} onClick={() => setShowingServers('disabled')}>
                    <div className="showing-info">Out of stock</div>
                </div>
            </LocationWrapper>
            {!showFilters && (
                <div className={'text-center'}>
                    <span className="underline hover:text-[#e90e0e] cursor-pointer"
                          onClick={() => setShowFilters(true)}>Show Advanced Filters</span>
                </div>
            )}
        </>
    );
}

const RAMValue = ({ram}: RAMValueProps) => {
    return (
        <div>
            {(ram.quantity && ram.quantity > 1 ? ram.quantity + ' x ' : '') + ram.capacity + 'GB ' + ram.type + ' ' + ram.speed + ' MHz'}
        </div>
    )
}

const DiskValue = ({disk}: DiskValueProps) => {
    return (
        <div>
            {(disk.quantity && disk.quantity > 1 ? disk.quantity + ' x ' : '') + disk.capacity + 'GB ' + disk.type}
        </div>
    )
}

const DedicatedServerValue = ({server}: DedicatedServerProps) => {
    const currency = useAppSelector((state) => state.currency.value);

    return (
        <div className={`text-white my-8 px-5 py-3 bg-[linear-gradient(180deg,#162032_0%,#161E2B_24%,#161E2B_100%)] border border-[#212A39] rounded-[6px] ${server.enabled ? 'hover:bg-[linear-gradient(180deg,#E90E0E21_0%,#E90E0E00_100%)] hover:border-[#e90e0e]' : 'server-out-of-stock'}`}>
            <div className="sm:flex sm:justify-between sm:items-center mb-3">
                <div className={'text-start flex items-center'}>
                    <img src={server.cpu.icon} alt="CPU" className={'me-3 h-[40px]'}/>
                    <div>
                        <h3 className={'text-lg font-proxima-bold'}>{server.name}</h3>
                        <div className={'flex items-center text-gray-400'}>
                            <FontAwesomeIcon icon={faMapPin} className={'w-[13px] h-[13px] me-1'}/>
                            <span>{server.location.city}, {server.location.country}</span>
                            <img
                                src={`/flags/${kebabCase(server.location.country)}.svg`}
                                alt={server.location.country}
                                className={`flag-${server.location.short_code} ms-2`}
                                width="14"
                                height="14"
                            />
                        </div>
                    </div>
                </div>
                {currency && (
                    <div className={'text-end'}>
                        <small>Starting at</small>
                        <div className={'text-end font-proxima-bold'}>
                            {currency.name}
                            {server.starting_price[currency.code]}
                            /month
                        </div>
                    </div>
                )}
            </div>
            <div className="lg:grid lg:grid-cols-5 lg:gap-8">
                <div className={'mb-2 lg:mb-0'}>
                    <small className={'uppercase text-gray-500'}>
                        <FontAwesomeIcon icon={faMicrochip} className={'me-1'} />
                        CPU
                    </small>
                    <div>
                        {`${server.cpu.name} - ${server.cpu.threads > 1 && server.cpu.threads + ' x '} ${server.cpu.speed}GHz`}
                    </div>
                </div>
                <div className={'mb-2 lg:mb-0'}>
                    <small className={'uppercase text-gray-500'}>
                        <FontAwesomeIcon icon={faMemory} className={'me-1'} />
                        RAM
                    </small>
                    <div>
                        {server.rams.map((ram, index) => <RAMValue ram={ram} key={index}/>)}
                    </div>
                </div>
                <div className={'mb-2 lg:mb-0'}>
                    <small className={'uppercase text-gray-500'}>
                        <FontAwesomeIcon icon={faHardDrive} className={'me-1'} />
                        Disk
                    </small>
                    <div>
                        {server.disks.map((disk, index) => <DiskValue disk={disk} key={index}/>)}
                    </div>
                </div>
                <div className={'mb-2 lg:mb-0'}>
                    <small className={'uppercase text-gray-500'}>
                        <FontAwesomeIcon icon={faGlobe} className={'me-1'} />
                        Transfer Speed
                    </small>
                    <div className={'flex items-center'}>
                        {server.transfer_speed}
                    </div>
                </div>
                <div className={'text-end pt-2'}>
                    {server.enabled && (
                        <Link to={'#'}>
                            <span className="me-5 underline hover:text-[#e90e0e]">Details</span>
                        </Link>
                    )}
                    <Button color={"red"} text={"Inquiry"} className="transition-transform transform hover:scale-105" />
                </div>
            </div>
        </div>
    );
}

const CpuThreadsFilter = ({threads, setThreads}: CpuThreadsFilterProps) => {
    return (
        <div>
            <span className="text-[13px] text-white mb-1">CPU Threads</span>
            <select value={threads} onChange={(e) => setThreads(e.target.value)}
                    className="h-[30px] py-[5px] border-[#2D3747] border text-[15px] rounded-[5px] w-full bg-[#0E1623]/0 px-[10px] outline-none text-white focus:border-[#E90E0E]">
                <option value="all">All</option>
                <option value="2">2 threads</option>
                <option value="4">4 threads</option>
                <option value="6">6 threads</option>
                <option value="8">8 threads</option>
                <option value="10">10 threads</option>
                <option value="12">12 threads</option>
                <option value="16">16 threads</option>
            </select>
        </div>
    );
}

const CpuSpeedFilter = ({setCpuSpeed}: CpuSpeedFilterProps) => {
    const [cpuSpeedMin, setCpuSpeedMin] = useState<number>(1.6);
    const [cpuSpeedMax, setCpuSpeedMax] = useState<number>(4.5);

    const setCpuSpeedValues = (value: number | number[]) => {
        if (Array.isArray(value) && value.length > 1) {
            setCpuSpeedMin(value[0]);
            setCpuSpeedMax(value[1]);
        }
    }

    const setCpuSpeedValue = (value: number | number[]) => {
        if (Array.isArray(value) && value.length > 1) {
            setCpuSpeed(value);
        }
    }

    return (
        <div>
            <span className="text-[13px] text-white mb-1">CPU Speed</span>
            <Slider
                range
                styles={{
                    track: {backgroundColor: "#e90e0e", height: "8px"},
                    rail: {backgroundColor: "rgba(14,22,35)", height: "8px"},
                    handle: {
                        borderColor: "rgba(233,14,14,0.57)",
                        height: 18,
                        width: 18,
                        marginTop: "-5px",
                        backgroundColor: "#292f3a",
                        opacity: 1,
                    }
                }}
                min={1.6}
                max={4.5}
                step={.1}
                allowCross={false}
                value={[cpuSpeedMin, cpuSpeedMax]}
                onAfterChange={setCpuSpeedValue}
                onChange={setCpuSpeedValues}
            />
            <div className={'flex justify-between'}>
                <small>{cpuSpeedMin}Ghz</small>
                <small>{cpuSpeedMax}Ghz</small>
            </div>
        </div>
    );
}

const RamCapacityFilter = ({setRamCapacity}: RamCapacityFilterProps) => {
    const [ramCapacityMin, setRamCapacityMin] = useState<number>(4);
    const [ramCapacityMax, setRamCapacityMax] = useState<number>(512);

    const setRamCapacityValues = (value: number | number[]) => {
        if (Array.isArray(value) && value.length > 1) {
            setRamCapacityMin(value[0]);
            setRamCapacityMax(value[1]);
        }
    }

    const setRamCapacityValue = (value: number | number[]) => {
        if (Array.isArray(value) && value.length > 1) {
            setRamCapacity(value);
        }
    }

    return (
        <div>
            <span className="text-[13px] text-white mb-1">RAM Capacity</span>
            <Slider
                range
                styles={{
                    track: {backgroundColor: "#e90e0e", height: "8px"},
                    rail: {backgroundColor: "rgba(14,22,35)", height: "8px"},
                    handle: {
                        borderColor: "rgba(233,14,14,0.57)",
                        height: 18,
                        width: 18,
                        marginTop: "-5px",
                        backgroundColor: "#292f3a",
                        opacity: 1,
                    }
                }}
                min={4}
                max={512}
                step={4}
                allowCross={false}
                value={[ramCapacityMin, ramCapacityMax]}
                onAfterChange={setRamCapacityValue}
                onChange={setRamCapacityValues}
            />
            <div className={'flex justify-between'}>
                <small>{ramCapacityMin}GB</small>
                <small>{ramCapacityMax}GB</small>
            </div>
        </div>
    )
}

const DiskTypeFilter = ({diskType, setDiskType}: DiskTypeFilterProps) => {
    return (
        <div>
            <span className="text-[13px] text-white mb-1">Disk type</span>
            <select value={diskType} onChange={(e) => setDiskType(e.target.value)}
                    className="h-[30px] py-[5px] border-[#2D3747] border text-[15px] rounded-[5px] w-full bg-[#0E1623]/0 px-[10px] outline-none text-white focus:border-[#E90E0E]">
                <option value="all">All</option>
                <option value="SATA III">SATA III</option>
                <option value="PCIe">PCIe</option>
            </select>
        </div>
    );
}

const LocationFilter = ({location, setLocation}: LocationFilterProps) => {
    const {isLoading: locationsLoading, data: locations, error: locationsError} = useQuery({
        queryKey: ['locations'],
        queryFn: getLocations
    })

    return (
        <>
            {!locationsLoading && !locationsError && locations && (
                <div>
                    <span className="text-[13px] text-white mb-1">Location</span>
                    <select value={location} onChange={(e) => setLocation(e.target.value)}
                            className="h-[30px] py-[5px] border-[#2D3747] border text-[15px] rounded-[5px] w-full bg-[#0E1623]/0 px-[10px] outline-none text-white focus:border-[#E90E0E]">
                        <option value="all">All</option>
                        {locations.map((item, index) => <option value={item.id}>{item.city+', '+item.country}</option>)}
                    </select>
                </div>
            )}
        </>
    )
}

const AdvancedFilters = ({
    threads,
    setThreads,
    setCpuSpeed,
    setRamCapacity,
    diskType,
    setDiskType,
    location,
    setLocation,
    setShowFilters,
}: AdvancedFiltersProps) => {
    return (
        <div className={'px-10 sm:px-2 lg:px-10'}>
            <div className="mx-auto rounded-md bg-[#00000099] px-5 py-3">
                <h5 className="text-[14px] pb-[8px] uppercase">
                    Filter servers
                </h5>
                <div className="lg:grid lg:grid-cols-5 lg:gap-8 w-full m-0 p-0">
                    <CpuThreadsFilter threads={threads} setThreads={setThreads}/>
                    <CpuSpeedFilter setCpuSpeed={setCpuSpeed}/>
                    <RamCapacityFilter setRamCapacity={setRamCapacity}/>
                    <DiskTypeFilter diskType={diskType} setDiskType={setDiskType}/>
                    <LocationFilter location={location} setLocation={setLocation}/>
                </div>
                <div className={'text-center'}>
                    <span className="underline hover:text-[#e90e0e] cursor-pointer"
                          onClick={() => setShowFilters(false)}>Hide Advanced Filters</span>
                </div>
            </div>

        </div>
    );
}

const DedicatedServers: React.FC = () => {
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [showingServers, setShowingServers] = useState<'all' | 'enabled' | 'disabled'>('enabled')
    const [threads, setThreads] = useState<string | number>('all');
    const [cpuSpeed, setCpuSpeed] = useState<number[]>([1.6, 4.5]);
    const [ramCapacity, setRamCapacity] = useState<number[]>([4, 512]);
    const [diskType, setDiskType] = useState<string>('all');
    const [location, setLocation] = useState<number | string>('all');
    const {
        isLoading,
        data: dedicatedServers,
        error,
        refetch,
    } = useQuery({
        queryKey: [
            'dedicated_servers',
            showingServers,
            threads,
            cpuSpeed,
            ramCapacity,
            diskType,
            location,
        ],
        queryFn: () => getDedicatedServers(
            showingServers,
            threads,
            cpuSpeed,
            ramCapacity,
            diskType,
            location,
        )
    });

    return (
        <div className={"w-full my-0 mx-auto"}>
            <section className="relative py-20 pt-48 h-auto pb-0 bg-center bg-no-repeat bg-cover" style={{backgroundImage: `url('/server3.png')`}}>
                <div className="max-w-7xl my-0 mx-auto px-4 w-full sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl">
                    <div className="flex flex-wrap mx-[-15px]">
                        <div className={"flex-grow text-center"}>
                            <h1 className={"text-6xl uppercase font-bold my-0 text-neutral-200"}>
                                Dedicated Servers
                            </h1>
                            <h2 className={"text-[18px] text-gray-200 mx-auto max-w-4xl leading-7 relative z-10 font-medium mb-32"}>
                                No setup fees, instant server deployment and powered by a low-latency anycast network
                                with global protection!
                            </h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="w-full flex justify-center my-20">
                <div>
                    {!error && (
                        <>
                            <TopFilter showingServers={showingServers} setShowingServers={setShowingServers} showFilters={showFilters} setShowFilters={setShowFilters} />
                            <Collapse isOpened={showFilters}>
                                <AdvancedFilters
                                    threads={threads}
                                    setThreads={setThreads}
                                    setCpuSpeed={setCpuSpeed}
                                    setRamCapacity={setRamCapacity}
                                    diskType={diskType}
                                    setDiskType={setDiskType}
                                    location={location}
                                    setLocation={setLocation}
                                    setShowFilters={setShowFilters}
                                />
                            </Collapse>
                        </>
                    )}
                    {isLoading ? (
                        <div
                            className="max-w-7xl my-0 mx-auto px-4 w-full sm:max-w-md md:max-w-lg lg:max-w-5xl xl:max-w-7xl">
                            <div className="flex flex-col items-center justify-center py-[100px]">
                                <FontAwesomeIcon icon={faSpinner} className="w-[100px] h-[100px] mb-10" spin/>
                                <h1 className="text-[24px] font-[600] text-white">
                                    Loading...
                                </h1>
                            </div>
                        </div>
                    ) : error ? (
                        <div className="error-container">
                            <p>Failed to load dedicated servers. Please try again later.</p>
                            <p>If this error persists, please reach out to us!</p>
                            <button onClick={() => refetch()}>Retry</button>
                        </div>
                    ) : (
                        <div className={'dedicated_servers px-10'}>
                            {dedicatedServers?.map((server, index) => <DedicatedServerValue server={server} key={index}/>)}
                        </div>
                    )}
                </div>
            </section>
            <CustomControlPanel/>
            <PromoContainer/>
            <GameFeatures/>
            <GameLocation/>
            <FAQ/>
            <Footer/>
        </div>
    );
};

export default DedicatedServers;
