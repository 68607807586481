import React, {useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {I18n} from "react-redux-i18n";
import Isotope from "isotope-layout";
import classNames from "classnames";
import {faSearch, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

import Footer from "../components/Footer";
import {CDN} from "../constants";
import {getGames} from "../utils";
import {Game} from "../types";
import GameControlPanel from "../components/GameControlPanel";
import FAQ from "../components/FAQ";
// @ts-ignore
import {kebabCase} from "lodash";
import {useQuery} from "@tanstack/react-query";
import {useAppSelector} from "../hooks";

const GameServers: React.FC = () => {
    const {
        isLoading: gamesLoading,
        data: games,
        error: gamesError,
        refetch
    } = useQuery({queryKey: ['games'], queryFn: getGames});
    const currency = useAppSelector((state) => state.currency.value);

    const isotope = useRef<Isotope | null>();
    // store the filter keyword in a state
    const [filterKey, setFilterKey] = React.useState("*");
    const [search, setSearch] = React.useState('');

    // initialize an Isotope object with configs
    useEffect(() => {
        if (!games) return;
        if (games[0]) {
            isotope.current = new Isotope(".filter-container", {
                itemSelector: ".filter-item",
                layoutMode: "fitRows",
            });
            // cleanup
            return () => isotope.current?.destroy();
        }
    }, [games]);

    // handling filter key change
    useEffect(() => {
        if (filterKey === "*") {
            isotope.current?.arrange({filter: `*`});
        } else {
            isotope.current?.arrange({filter: `.${filterKey}`});
            setSearch('');
        }
    }, [filterKey]);

    useEffect(() => {
        if (search === '') {
            if (filterKey === "*") isotope.current?.arrange({filter: `*`});
            return;
        }
        isotope.current?.arrange({
            filter: function(itemElement) {
                return itemElement.classList[0].includes(search);
            }
        });
    }, [search, filterKey]);

    const handleFilterKeyChange = (key: string) => () => setFilterKey(key);

    const navigate = useNavigate();

    if (gamesLoading) {
        return (
            <div className="flex flex-col items-center justify-center py-[100px]">
                <FontAwesomeIcon icon={faSpinner} className="w-[100px] h-[100px] mb-10" spin />
                <h1 className="text-[24px] font-[600] text-white">
                    Loading...
                </h1>
            </div>
        );
    }

    if (gamesError) {
        return (
            <div className="error-container">
                <p>Failed to load games. Please try again later.</p>
                <p>If this error persists, please reach out to us!</p>
                <button onClick={() => refetch()}>Retry</button>
            </div>
        );
    }

    return (
        <div className={"w-full my-0 mx-auto"}>
            <div
                className="relative py-20 pt-48 h-auto pb-0 bg-center bg-no-repeat bg-cover"
                style={{backgroundImage: `url("/bg-games.png")`}}
            >
                <div className="max-w-7xl my-0 mx-auto px-4 w-full">
                    <div className="flex flex-wrap mx-[-15px]">
                        <div className={"flex-grow text-center"}>
                            <h1
                                className={
                                    "md:text-6xl text-[40px] uppercase font-bold my-0 text-neutral-200"
                                }
                            >
                                {I18n.t("gameServers")}
                            </h1>
                            <h2
                                className={
                                    "md:text-[30px] text-[24px] text-white uppercase font-[900] mx-auto px-4 relative z-10"
                                }
                            >
                                SUPER FAST. SECURE. AND RELIABLE.
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="flex text-center grow">
                    <div className="text-center w-full inline-flex items-center justify-center flex-wrap pt-[7.5rem]">
                        <div className="flex mx-5 flex-wrap justify-center">
                            <h1 className="font-[900] text-white text-[24px] uppercase">
                                Excellent
                            </h1>
                            <div className="flex justify-center w-[150px] md:ml-5 mx-5 h-[28px]">
                                <img
                                    src={`${CDN}images/fourfivestars.png`}
                                    className=""
                                    alt="four"
                                />
                            </div>
                        </div>
                        <span className="block my-2">
              Based on over{" "}
                            <Link
                                className="border-b border-dotted hover:text-white"
                                to="https://www.trustpilot.com/review/fragnet.net"
                            >
                <strong className="text-white font-bold">125+</strong> verified
                reviews on
              </Link>
            </span>
                        <Link
                            className="flex justify-center"
                            to="https://www.trustpilot.com/review/fragnet.net"
                        >
                            <img
                                src={`${CDN}images/games-trustpilot.png`}
                                className="h-[24px] ml-5"
                                alt="four"
                            />
                        </Link>
                    </div>
                </div>
                <div className="container max-w-[1280px] mt-4 rounded-md bg-[#00000099] mx-auto">
                    <div className="grid lg:grid-cols-3 grid-cols-1 w-full m-0 p-0">
                        <div className="col col-span-1 py-[20px] px-[22px] border-r-2 border-[#101522]">
                            <h5 className="text-[13px] pb-[8px] text-white font-[700] uppercase">
                                Find your game
                            </h5>
                            <div className="inline-flex items-center">
                                <FontAwesomeIcon
                                    className="font-[900] text-[#727681]"
                                    icon={faSearch}
                                />
                                <input
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    type="text"
                                    className="ml-2 text-[#a7aebd] text-[16px] px-[2px] py-[1px] bg-[#00000000] outline-none w-[250px]"
                                    placeholder="Enter your game server here"
                                />
                            </div>
                        </div>
                        <div className="col col-span-2 p-[20px]">
                            <h5 className="text-[13px] pb-[8px] text-white font-[700] uppercase">
                                Filter by game type
                            </h5>
                            <ul className="inline-flex items-center flex-wrap">
                                <li
                                    className={classNames(
                                        "mr-2 text-[#aab0be] px-[12px] py-[2px] text-[15px] font-[600] rounded-[3px] cursor-pointer mb-3",
                                        filterKey === "*" || search !== ''
                                            ? "bg-[#e90e0e] text-white"
                                            : "bg-[#292f3a]"
                                    )}
                                    onClick={handleFilterKeyChange("*")}
                                >
                                    All Games
                                </li>
                                <li
                                    className={classNames(
                                        "mr-2 text-[#aab0be] px-[12px] py-[2px] text-[15px] font-[600] rounded-[3px] cursor-pointer mb-3",
                                        filterKey === "popular" && search === ''
                                            ? "bg-[#e90e0e] text-white"
                                            : "bg-[#292f3a]"
                                    )}
                                    onClick={handleFilterKeyChange("popular")}
                                >
                                    Popular
                                </li>
                                <li
                                    className={classNames(
                                        "mr-2 text-[#aab0be] px-[12px] py-[2px] text-[15px] font-[600] rounded-[3px] cursor-pointer mb-3",
                                        filterKey === "fps" && search === ''
                                            ? "bg-[#e90e0e] text-white"
                                            : "bg-[#292f3a]"
                                    )}
                                    onClick={handleFilterKeyChange("fps")}
                                >
                                    FPS
                                </li>
                                <li
                                    className={classNames(
                                        "mr-2 text-[#aab0be] px-[12px] py-[2px] text-[15px] font-[600] rounded-[3px] cursor-pointer mb-3",
                                        filterKey === "survival" && search === ''
                                            ? "bg-[#e90e0e] text-white"
                                            : "bg-[#292f3a]"
                                    )}
                                    onClick={handleFilterKeyChange("survival")}
                                >
                                    Survival
                                </li>
                                <li
                                    className={classNames(
                                        "mr-2 text-[#aab0be] px-[12px] py-[2px] text-[15px] font-[600] rounded-[3px] cursor-pointer mb-3",
                                        filterKey === "sandbox" && search === ''
                                            ? "bg-[#e90e0e]  text-white"
                                            : "bg-[#292f3a]"
                                    )}
                                    onClick={handleFilterKeyChange("sandbox")}
                                >
                                    Sandbox
                                </li>
                                <li
                                    className={classNames(
                                        "mr-2 text-[#aab0be] px-[12px] py-[2px] text-[15px] font-[600] rounded-[3px] cursor-pointer mb-3",
                                        filterKey === "simulation" && search === ''
                                            ? "bg-[#e90e0e] text-white"
                                            : "bg-[#292f3a]"
                                    )}
                                    onClick={handleFilterKeyChange("simulation")}
                                >
                                    Simulation
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto max-w-[1280px] py-12">
                {games && (
                    <div className="grid grid-cols-4 filter-container">
                        {games
                            .sort((a: Game, b: Game) => Number(a.popular) - Number(b.popular))
                            .map((game: Game, index: number) => (
                                <div
                                    key={index}
                                    onClick={() => navigate(`/games/${game.slug}`)}
                                    className={classNames(
                                        `${kebabCase(game.name)} col px-3 filter-item xl:w-1/4 lg:w-1/3 w-1/2`,
                                        {
                                            survival: game.type === "Survival",
                                            sandbox: game.type === "Sandbox",
                                            simulation: game.type === "Simulator",
                                            fps: game.type === "FPS",
                                            popular: game.popular !== "0",
                                        }
                                    )}
                                >
                                    <div
                                        className="rounded-xl md:h-[180px] h-[150px] w-full mb-5 p-[8px] hover:scale-[.97] duration-200 group cursor-pointer"
                                        style={{
                                            background: `url('${game.hero_slider_img}') 0 0/cover`,
                                        }}
                                    >
                                        <h1 className="mb-3 rounded-[3px] mt-3 text-white bg-[#293446] skew-x-[-15deg] text-[14px] font-[600] inline-block px-[12px] shadow-[0_1px_2px_0_rgba(0,0,0,0.79),inset_0_-1px_1px_0_rgba(55,0,0,0.25)] uppercase">
                                            {game.name.split(":")[0]}
                                        </h1>
                                        {game.starting_price !== null && currency && (
                                            <span className="px-[8px] py-[2px] text-sm font-bold rounded bg-black/80 group-hover:bg-[#e90e0e] group-hover:text-white">
                                                Starting from {currency.name} {game.starting_price[currency.code]}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
                <div className="ml-3">
                    <h1 className="text-white font-[600] mt-5">
                        Don’t see your game server listed?
                    </h1>
                    <span>Chances we’re already hosting it or it is coming soon!</span>
                </div>
            </div>
            <GameControlPanel/>
            <FAQ/>
            <Footer/>
        </div>
    );
};

export default GameServers;
