import React, { useRef } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { I18n } from "react-redux-i18n";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const GameControlPanel = () => {
  const slider = useRef<Slider>(null);
  const nextSlide = () => {
    slider?.current?.slickNext();
  };
  const prevSlide = () => {
    slider?.current?.slickPrev();
  };
  return (
    <div className="w-full flex justify-center pt-[88px] bg-[#171c29]">
      <div className="container max-w-[1280px] md:flex">
        <div className="lg:w-2/3 md:w-1/2 px-4 relative mb-20">
          <Slider adaptiveHeight={true} ref={slider} arrows={false}>
            <div className="w-full">
              <img
                className="mx-auto"
                src="/fragify-dashboard.png"
                alt="dashboard"
              />
            </div>
            <div className="w-full">
              <img
                className="mx-auto"
                src="/fragify-modpacks.png"
                alt="modpacks"
              />
            </div>
            <div className="w-full">
              <img className="mx-auto" src="/fragify-editor.png" alt="editor" />
            </div>
            <div className="w-full">
              <img
                className="mx-auto"
                src="/control-panel-1.png"
                alt="control-panel"
              />
            </div>
            <div className="w-full">
              <img
                className="mx-auto"
                src="/control-panel-2.png"
                alt="control-panel"
              />
            </div>
            <div className="w-full">
              <img
                className="mx-auto"
                src="/control-panel-3.png"
                alt="control-panel"
              />
            </div>
            <div className="w-full">
              <img
                className="mx-auto"
                src="/control-panel-4.png"
                alt="control-panel"
              />
            </div>
          </Slider>
          <div
            className="absolute top-0 right-20 h-full items-center flex text-[25px] hover:text-white"
            onClick={nextSlide}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
          <div
            className="absolute top-0 left-20 h-full items-center flex text-[25px] hover:text-white"
            onClick={prevSlide}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
        </div>
        <div className="lg:w-1/3 md:w-1/2 px-3">
          <h2 className="uppercase text-[36px] font-[900] pb-[6px] md:text-start text-center lg:mt-8 text-white">
            CUSTOM CONTROL PANEL
          </h2>
          <p className="pb-[20px] md:text-start text-center">
            Edit files with ease, keep your server up to date, and install a
            wide variety of mods with just one click.
          </p>
          <div className="md:text-start text-center mb-10">
            <Link
              to="./#"
              className="inline-flex rounded-md z-50 text-center md:mx-0 mx-auto items-center h-[48px] px-[24px] text-[16px] font-[800] bg-[#e90e0e] text-white hover:bg-[#f91d1d] text-white"
            >
              {I18n.t("getStarted1")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameControlPanel;
