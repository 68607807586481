const serverNames = [
    "PixelFrontier",
    "RusticRealm",
    "HarvestMoon",
    "ShadowLand",
    "MysticValley",
    "EchoRealm",
    "MysticNexus",
    "ThunderPeak",
    "SolarFlare",
    "CrimsonTide",
    "IronFortress",
    "NovaHaven",
    "ShadowVale",
    "TerraFrontier",
    "VortexDomain",
    "Whispering Sands Oasis",
    "Crimson Twilight Harbor",
    "Mystic Frost Summit",
    "Eclipse Shadow Valley",
    "Galactic Dawn Patrol",
    "Ancient Rune Forest",
    "Lost Atlantis Voyage",
    "Sunken Pirate Cove",
    "Legends of the Ancients",
    "Seekers in the Void",
    "Mysteries of the Depths",
    "Adventures in Hidden Valleys",
    "Journey to the Unknown",
    "Echoes from the Starvoid"
];

export default serverNames;