import React from "react";
import {Link} from "react-router-dom";
import {
    faInfinity,
    faCalendarAlt,
    faShieldAlt,
    faDatabase,
    faFileAlt,
    faCloudDownloadAlt,
    faHdd,
    IconDefinition, faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import {Game} from "../types";
import Button from "../components/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PromoContainer from "../components/PromoContainer";
import Footer from "../components/Footer";
import GameLocation from "../components/GameLocation";
import GameFeatures from "../components/GameFeatures";
import GetStarted from "../components/GetStarted";
import {useAppSelector} from "../hooks";
import {useQuery} from "@tanstack/react-query";
import {getParams} from "../utils";

interface GameTemplateProps {
    game: Game;
}

const iconList: { [key: string]: IconDefinition } = {
    faInfinity: faInfinity,
    faCalendarAlt: faCalendarAlt,
    faShieldAlt: faShieldAlt,
    faDatabase: faDatabase,
    faFileAlt: faFileAlt,
    faCloudDownloadAlt: faCloudDownloadAlt,
    faHdd: faHdd,
};

const GameTemplateTwo: React.FC<GameTemplateProps> = ({game}) => {
    const currency = useAppSelector((state) => state.currency.value);
    const { isLoading: paramsLoading, data: params, error: paramsError } = useQuery({queryKey: ['global_params'], queryFn: getParams});

    if (paramsLoading) {
        return (
            <div className="flex flex-col items-center justify-center py-[200px]">
                <FontAwesomeIcon icon={faSpinner} className="w-[100px] h-[100px] mb-10" spin />
                <h1 className="text-[24px] font-[600] text-white">
                    Loading...
                </h1>
            </div>
        );
    }

    if (paramsError) {
        return (
            <div className="error-container text-center py-[200px]">
                <p className="text-white text-[24px] font-[600] mb-4">Failed to load the game. Please try again later.</p>
            </div>
        );
    }

    return (
        <div className={"w-full my-0 mx-auto"}>
            <section
                className="relative py-20 pt-48 h-auto pb-0 bg-center bg-no-repeat bg-cover"
                style={{backgroundImage: `url(${game.background})`}}
            >
                <div className="max-w-7xl my-0 mx-auto px-4 w-full sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl">
                    <div className="flex flex-wrap mx-[-15px]">
                        <div className={"flex-grow text-center"}>
                            <h1
                                className={"text-6xl uppercase font-bold my-0 text-neutral-200"}
                            >
                                {game.name}
                            </h1>
                            <h2
                                className={
                                    "text-[18px] text-gray-200 mx-auto max-w-4xl leading-7 relative z-10 font-medium mb-32"
                                }
                            >
                                {game.description}
                            </h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="game-pricing py-20 text-center container m-auto">
                <div className="my-0 mx-auto px-4 w-full">
                    <div className="text-center">
                        <h2 className="mb-2 mt-2 font-bold font-mono uppercase text-3xl text-white inline-block">
                            Choose Your Perfect Package
                        </h2>
                        <div className="bg-red-500 h-1 w-1/4 mx-auto my-2 mb-10"></div>
                    </div>
                    <div className="grid sm:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3 gap-4">
                        {game.packages.map((pkg, index) => (
                            <div
                                className="w-full px-2 mb-4 relative text-slate-100"
                                key={index}
                            >
                                <div
                                    className={`relative rounded overflow-hidden shadow-lg transform hover:-translate-y-4 transition-transform duration-300 border-4 border-slate-800`}
                                >
                                    {index === 1 && (
                                        <span className="absolute top-0 right-0 bg-red-500 text-white text-xs px-2 py-1 rounded-bl-full">
                                            Recommended
                                        </span>
                                    )}
                                    <div className="p-4 bg-slate-800 text-white shadow-md border-red-500 mb-4">
                                        <h2 className="font-bold uppercase text-2xl">{pkg.name}</h2>
                                        <p className="text-sm text-slate-300">
                                            Recommended for {pkg.recommended_players} players
                                        </p>
                                    </div>
                                     Package content
                                    {currency && params && (
                                        <>
                                            {params.discount_percent > 0 && (
                                                <small className="font-light text-xl text-slate-400 line-through mr-2 mt-4">
                                                        {currency.name}
                                                        {pkg.price[currency.code]}
                                                        /month
                                                </small>
                                            )}
                                            <div className="flex justify-center">
                                                <h3 className="text-3xl font-extrabold mt-2 mb-1">
                                                    {currency.name}
                                                    {(pkg.price[currency.code] - (pkg.price[currency.code] * params.discount_percent / 100)).toFixed(2)}
                                                </h3>
                                                <small className="font-medium text-lg text-slate-400 pt-4">
                                                    /month
                                                </small>
                                            </div>
                                        </>
                                    )}
                                    <div className={"flex justify-center items-center"}>
                                        <ul className="pt-2 text-center">
                                            {/* Features of the package */}
                                            {pkg.features.map((feature, index) => {
                                                const [firstWord, ...remainingWords] =
                                                    feature.name.split(" ");
                                                return (
                                                    <li
                                                        className="my-1 pb-1 flex items-center"
                                                        key={index}
                                                    >
                                                        <span style={{width: "24px"}}>
                                                            <FontAwesomeIcon
                                                                icon={iconList[feature.icon]}
                                                                size={"lg"}
                                                            />
                                                        </span>
                                                        <div>
                                                            <span className={"font-bold ml-4"}>
                                                                {firstWord}
                                                            </span>
                                                            <span>{remainingWords.join(" ")}</span>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                    <Link to={`/games/${game.slug}/order/${pkg.id}`}>
                                        <Button color={"red"} text={"Order Now"} className="transition-transform transform hover:scale-105 mb-6" />
                                    </Link>
                                </div>
                            </div>
                        ))}

                        {/* Add Custom Configuration Package */}
                        <div className="w-full px-2 mb-4 text-slate-100">
                            <div
                                className="py-10 relative px-8 text-center h-full rounded-lg overflow-hidden shadow-md transform hover:scale-105 transition-transform duration-300 bg-center bg-no-repeat"
                                style={{
                                    background: `url(${game.background}) center center no-repeat`,
                                }}
                            >
                                <h5 className={"text-2xl uppercase font-bold mb-3"}>
                                    Custom Configuration
                                </h5>
                                <p className="text-[16px] text-gray-300 mb-3">
                                    Not finding what you're looking for? Customize your package!
                                </p>
                                <ul className="mb-4 pt-2">
                                    <li className="my-1 pb-1">
                                        Up to <span className={"font-bold"}>4 CPU Threads</span>
                                    </li>
                                    <li className="my-1 pb-1">
                                        Up to <span className={"font-bold"}>32 GB RAM</span>
                                    </li>
                                </ul>
                                <Link to={`/games/${game.slug}/order`}>
                                    <Button
                                        color={"red"}
                                        text={"Configure Now"}
                                        className="transition-transform transform hover:scale-110 px-5 py-2"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PromoContainer/>
            <GameFeatures/>
            <GameLocation/>
            <GetStarted/>
            <Footer/>
        </div>
    );
};

export default GameTemplateTwo;
