import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {I18n, setLocale} from "react-redux-i18n";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../store";
import {Link} from "react-router-dom";
import VerticalMenu, {MenuItem} from "./VertcalDropdownMenu";
import ServicesDropDown from "./ServicesDropDown";
import {setCurrency} from '../reducers/currencySlice';
import {useAppSelector} from "../hooks";
import {currencies} from "../configs";

const HideMenu = styled.div`
  display: block;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const BottomOverlay = styled.div`
  position: absolute;
  padding: 0 20px;
  bottom: 28px;
  text-align: center;
  left: 0;
  right: 0;
  width: 100%;
`;

const OrderButton = styled.div`
  float: right;
  padding: 0;
  width: 48%;
`;

const OutlineWhite = styled.div`
  width: 48%;
  float: left;
`;

interface LangMenu {
    code: string;
    name: string;
}

const Navbar = () => {
    const [isHamburgerOpen, setIsHamburgerOpen] = useState<boolean>(false);
    const locale = localStorage.getItem("locale")
        ? localStorage.getItem("locale")
        : "en";
    const currencyString = localStorage.getItem("currency");
    const currencyValue = useAppSelector((state) => state.currency.value);
    const dispatch: AppDispatch = useDispatch();

    const localeData = [
        {code: "sw", name: "Swedish"},
        {code: "en", name: "English"},
        {code: "es", name: "Español"},
        {code: "ru", name: "Русский"},
        {code: "nl", name: "Dutch"},
        {code: "pl", name: "Polski"},
        {code: "de", name: "Deutsch"},
        {code: "fr", name: "Français"},
    ];

    let langMenu: LangMenu = {code: "en", name: "English"};

    localeData.forEach((e) => {
        if (e.code === locale) langMenu = e;
    });

    const genericHamburgerLine = `h-[3px] w-[28px] my-[4.5px] rounded-full transition ease transform duration-300 `;

    const changeCurrency = (value: any) => {
        let selectedCurrency = currencies.find(c => c.id === value);
        if (!selectedCurrency) return;

        localStorage.setItem("currency", JSON.stringify(selectedCurrency));
        dispatch(setCurrency(selectedCurrency));
    };

    const LanguageMenuItem: React.FC<LangMenu> = ({ code, name }) => (
        <div
            className="flex items-center"
            onClick={() => {
                localStorage.setItem("locale", code);
                dispatch<any>(setLocale(code));
                window.location.reload();
            }}
        >
            <span className={`bg-language-sprite bg-${code} h-[25px] w-[40px]`}></span>
            <span className="ml-3">{name}</span>
        </div>
    );

    let currencyItems: MenuItem[] = [];
    for (let current of currencies) {
        currencyItems.push({
            label: (
                <div className="flex">
                    {/* Dirty hack for preloading the tailwind classes */}
                    <div className="hidden bg-eur bg-gbp bg-sek bg-usd">...</div>
                    <div
                        className={`h-[24px] w-[24px] bg-currency-sprite bg-${current.code}`}
                        role="img"
                        aria-label={current.code.toUpperCase()}
                    />
                    <span className="ml-3">{current.code.toUpperCase() + ' ' + current.name}</span>
                </div>
            ),
            value: current.id,
            onChange: changeCurrency,
        });
    }

    useEffect(() => {
        if (currencyString === null) return;
        const currency = JSON.parse(currencyString);
        dispatch(setCurrency(currency));
    }, [dispatch, currencyString]);

    return (
        <div>
            <nav
                className={`bg-[#0c101a] top-0 left-0 w-full z-20 bg-opacity-50 h-[86px] py-6 ${
                    isHamburgerOpen ? "fixed" : "absolute"
                }`}
            >
                <div className="max-w-[1280px] mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <div className="w-8/12 md:w-auto lg:w-auto">
                                <Link to={"/"} className="fixlogo">
                                    <img src="https://b-cdn.fragnet.net/images/logo.png" className="align-text-top" alt="Fragnet Logo"/>
                                </Link>
                            </div>
                            <div className="hidden lg:block ml-5">
                                <div className="col-auto col-lg-auto hidemobile">
                                    <ul className={'font-proxima-bold'}>
                                        <li className="inline-block px-3">
                                            <Link className="uppercase font-bold text-[13px] text-white" to={"/page/gameservers/"}>
                                                {I18n.t("gameServers")}
                                            </Link>
                                        </li>
                                        <VerticalMenu
                                            menuName={I18n.t("services")}
                                            items={[
                                                {
                                                    label: (
                                                        <div>
                                                            {I18n.t("discordBots")}{" "}
                                                            <span className="font-bold text-red-600">
                                                                {I18n.t("new")}
                                                            </span>
                                                        </div>
                                                    ),
                                                    url: "/page/discord-bots/",
                                                },
                                                {
                                                    label: <div>{I18n.t("voiceServers")}</div>,
                                                    url: "/page/voiceservers/",
                                                },
                                                {
                                                    label: (
                                                        <div>
                                                            {I18n.t("dedicatedServers")}{" "}
                                                            {/*<span className="font-bold text-red-600">*/}
                                                            {/*    {I18n.t("soon")}*/}
                                                            {/*</span>*/}
                                                        </div>
                                                    ),
                                                    url: "/page/dedicatedServers/",
                                                },
                                            ]}
                                        />
                                        <li className="inline-block px-3 uppercase">
                                            <Link
                                                to={"/page/about/"}
                                                className="font-proxima-nova font-semibold text-[13px] text-white"
                                            >
                                                {I18n.t("aboutUs")}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto col-lg-auto ml-auto">
                            <ul className="flex right-nav items-center font-proxima-bold">
                                <HideMenu>
                                    <VerticalMenu
                                        menuName={I18n.t("login")}
                                        items={[
                                            {
                                                label: <div>{I18n.t("gameVoicePanel")}</div>,
                                                url: "https://gamepanel.fragnet.net",
                                            },
                                            {
                                                label: <div>{I18n.t("supportCenter")}</div>,
                                                url: "/",
                                            },
                                            {
                                                label: (
                                                    <div>
                                                        Fragify {I18n.t("panel")}{" "}
                                                        <span className="font-bold text-red-600">NEW</span>
                                                    </div>
                                                ),
                                                url: "https://panel.fragify.net",
                                            },
                                        ]}
                                    />
                                </HideMenu>
                                <HideMenu>
                                    {/* Dirty hack for preloading the tailwind classes */}
                                    <div className="hidden bg-en bg-pl bg-sw bg-ru bg-de bg-fr bg-es bg-nl"></div>
                                    <VerticalMenu
                                        menuName={langMenu.name}
                                        items={localeData.map(lang => ({
                                            label: <LanguageMenuItem {...lang} />,
                                            url: "/"
                                        }))}
                                    />
                                </HideMenu>
                                <div className="hidden lg:block">
                                    <VerticalMenu
                                        menuName={currencyValue?.name || ''}
                                        items={currencyItems}
                                    />
                                </div>
                                <li className="hidden lg:inline-block px-4 ">
                                    <Link
                                        to="https://fragnet.net/page/gameservers"
                                        className="border text-[13px] text-white inline-block border-white font-bold py-2 hover:bg-white hover:text-red-600 text-center px-5 rounded-md uppercase"
                                    >
                                        {I18n.t("orderNow")}
                                    </Link>
                                </li>
                                <li className="inline-block">
                                    <div className="button_container" id="toggle">
                                        <span className="top"></span>
                                        <span className="middle"></span>
                                        <span className="bottom"></span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="-mr-2 flex lg:hidden">
                            <button
                                className="flex flex-col h-12 w-12 rounded justify-center items-center group mt-0 bg-transparent p-0"
                                onClick={() => setIsHamburgerOpen(!isHamburgerOpen)}
                            >
                                <div
                                    className={`${genericHamburgerLine} ${
                                        isHamburgerOpen
                                            ? "rotate-45 translate-y-3  group-hover:opacity-100 bg-[#e90e0e]"
                                            : " group-hover:opacity-100 bg-white"
                                    }`}
                                />
                                <div
                                    className={`${genericHamburgerLine} ${
                                        isHamburgerOpen
                                            ? "opacity-0"
                                            : " group-hover:opacity-100 bg-white"
                                    }`}
                                />
                                <div
                                    className={`${genericHamburgerLine} ${
                                        isHamburgerOpen
                                            ? "-rotate-45 -translate-y-3  group-hover:opacity-100 bg-[#e90e0e]"
                                            : "group-hover:opacity-100 bg-white"
                                    }`}
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
            <div
                className={`${
                    isHamburgerOpen ? "block h-screen" : "block"
                } lg:hidden z-[19] top-0 left-0 w-full h-0 bg-[#0c101a] transition-height duration-200 inset-0 fixed`}
            >
                {isHamburgerOpen && (
                    <ul className="flex flex-col items-center h-full justify-center ">
                        <li className="inline-block px-[15px] py-[6px]">
                            <Link
                                to={"/"}
                                className=" font-proxima-nova font-medium text-[16px] text-white"
                                onClick={() => setIsHamburgerOpen(false)}
                            >
                                {I18n.t("home")}
                            </Link>
                        </li>
                        <li className="inline-block px-[15px] py-[6px]">
                            <Link
                                to={"/page/gameservers/"}
                                className=" font-proxima-nova font-medium text-[16px] text-white"
                                onClick={() => setIsHamburgerOpen(false)}
                            >
                                {I18n.t("gameServers")}
                            </Link>
                        </li>
                        <ServicesDropDown setIsHamburgerOpen={setIsHamburgerOpen} />
                        <li className="inline-block px-[15px] py-[6px]">
                            <Link
                                to={"/page/about/"}
                                className="font-proxima-nova font-medium text-[16px] text-white"
                                onClick={() => setIsHamburgerOpen(false)}
                            >
                                {I18n.t("aboutUs")}
                            </Link>
                        </li>
                        <li className="inline-block px-[15px] py-[6px]">
                            <Link
                                to={"/"}
                                className="font-proxima-nova font-medium text-[16px] text-white"
                                onClick={() => setIsHamburgerOpen(false)}
                            >
                                {I18n.t("support")}
                            </Link>
                        </li>
                    </ul>
                )}

                <BottomOverlay>
                    <OutlineWhite>
                        <Link
                            to={"https://clients.fragnet.net/login?language=english"}
                            className="w-full inline-block h-[48px] leading-[48px] border rounded-md text-white text-[16px] font-medium font-proxima shadow-sm"
                        >
                            {I18n.t("login")}
                            {">"}
                        </Link>
                    </OutlineWhite>
                    <OrderButton>
                        <Link
                            to={"https://fragnet.net/page/gameservers"}
                            className="w-full inline-block h-[48px] leading-[48px] rounded-md text-white text-[14px] font-bold font-proxima shadow-sm bg-[#e90e0e]"
                        >
                            <span>{I18n.t("orderNow")}</span>
                        </Link>
                    </OrderButton>
                </BottomOverlay>
            </div>
        </div>
    );
};

export default Navbar;
